﻿.warning-banner {
  background-color: #ffee00;
  padding: 15px;
  margin-bottom: 20px;
}

.warning-banner p {
  font-family: "oswaldlight", Arial, sans-serif;
  font-weight: normal;
  line-height: 1.35;
  margin: 18px 0 4.61538px;
  padding-top: 6px;
  margin: 0;
  font-size: 24px;
}

.warning-banner [class*="column-"] {
  padding: 0;
  margin: 0;
}

@media (max-width: 940px) {
  .warning-banner {
    margin-bottom: 0;
  }
}

﻿* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

a {
  @include transition(color 200ms ease-in);
  color: $font-color-main;
  cursor: pointer;
  text-decoration: none;

  &.active,
  &.active i,
  &.active:before,
  &.active:after,
  &.submenu-toggle:hover {
    color: $font-color-main;
  }

  &:hover {
    color: $color-anchor-hover;
  }

  &.skiptomaincontent {
    left: -1000em;
    position: absolute;
  }
}
.rte-cta {
  background-color: #36864a;
  border: none;
  color: #ffffff !important;
  display: inline-block;
  font-family: $font-family-headline;
  margin: 30px 0;
  padding: 12px 30px 9px 50px;
  position: relative;
  text-align: left;
  transition: background-color 0.3s ease;
  text-decoration: none !important;
  min-width: 150px;
  font-size: 19px !important;
  &:hover {
    background-color: #317943;
    color: white;
  }
  &:before {
    background-color: rgb(0, 0, 0);
    bottom: 0px;
    content: "\f105";
    font-size: 27px;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 13px;
    font-family: "FontAwesome";
    font-weight: normal;
    position: absolute;
  }
  a{
      color:inherit;
      text-decoration: none !important;
  }
}
body {
  background: $content-background;
  color: $font-color-main;
  font-family: $font-family-main;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;

  &.show-menu {
    overflow: hidden;
  }
}
.lock {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
}
.bottom-spacer {
  background-color: $content-background;
  height: 30px;
  width: 100%;

  &.white {
    background-color: #ffffff;
  }
}

.container {
  margin: 0 auto;
  max-width: $max-width;
  position: relative;
  width: 100%;
}

.cover-image {
  @extend %image-cover;
}

div {
  &.lblhiddenfield {
    display: inline-block;
    width: 150px;
  }
}

.float-right {
  float: right;
  padding: 0;

  @media (min-width: $breakpoint) {
    padding-right: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headline;
  font-weight: normal;
  line-height: $font-line-height-base;
  margin: $font-size-base * 1.2 0 $font-size-base/3.25;
  padding-top: 6px;

  & + p {
    margin: 0 0 $font-size-base;
  }
}

h1 {
  font-size: $font-size-base * 2;
}

h2 {
  font-size: $font-size-base * 1.6;
}

h3 {
  font-size: $font-size-base * 1.4;
}

h4 {
  font-size: $font-size-base * 1.3;
}

h5 {
  font-size: $font-size-base * 1.2;
}

h6 {
  font-size: $font-size-base * 1.1;
}

.hide-mobile {
  display: none;

  @media (min-width: $breakpoint) {
    display: block;
  }
}

.hidden {
  display: none;
}

.hiddenfieldsinpageedit {
  border: 1px solid #000000;
  color: #999;
  margin-bottom: 10px;
  padding: 5px 5px 10px 5px;
}

img {
  border: none;
  height: auto;
  max-width: 100%;

  &.editspotlink {
    height: 16px !important;
    width: 16px !important;
  }
}

/*input {

	&:focus {
		outline: none;
	}
}*/

input[type="text"],
input[type="password"],
textarea {
  @include appearance(none);
}

.no-padding {
  padding: 0;
}

p {
  font-size: $font-size-base;
  line-height: $font-line-height-base;
  margin: $font-size-base 0;

  @media (min-width: $breakpoint) {
    line-height: $font-line-height-base * 1.05;
  }
}

p,
span {
  @include word-wrap;
  word-break: normal;
}

section {
  &.white {
    background-color: #ffffff;
  }
}

.section-headline {
  color: #696969;
  font-size: 22px;
  margin: 10px 0 0 0;
  text-transform: uppercase;

  @media (min-width: $breakpoint) {
    margin: 40px 0 20px 0;
  }

  &.double-margin-top {
    margin: 20px 0 0 0;

    @media (min-width: $breakpoint) {
      margin: 50px 0 20px 0;
    }
  }

  &.no-headline {
    margin: 20px 0 0 0;

    @media (min-width: $breakpoint) {
      margin: 20px 0 20px 0;
    }
  }
}

.styles_element_summary {
    &,
    p {
        font-family: 'oswald_regularregular';
        font-size: 24px;
        font-weight: normal;
        line-height: $font-line-height-base;
        margin: $font-size-base * 1.2 0 $font-size-base/3.25;
        padding-top: 6px;
    }
}
/*
table {
  overflow: auto;
}*/

.contact-info-header {
  font-size: 22px;
  font-weight: bold;
  color: black;
}

.editmodecomment {
  font-size: 12px;
}

.page-wrapper {
  min-height: 100vh;
  overflow-x: hidden;
}

.img-16_9 {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: "object-fit: cover; object-position: center;";
  }
}

body span[scfieldtype="rich text"]{
    display: block ;
}
﻿.menu-top {
	@extend %show-desktop-only;

	background-color: #FFFFFF;
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    clear: both;
	padding: 0 $grid-gutter;

	a {
		@include transition(border-color 200ms ease);

		border-bottom: 1px solid transparent;
		display: block;
		font-family: $font-family-headline;
		font-size: 31px;
		padding-top: 6px;
		text-transform: uppercase;

		&.active {
			color: $color-brand;
		}

		&:hover {
			border-color: #BFBFBF;
			color: $font-color-main;
		}
	}

	.autocomplete-suggestions {
		margin-left: -5px;
		margin-top: 5px;
	}

	li {
		display: inline-block;
		float: left;
		margin-right: 20px;
		padding: 16px 0;
	}

	.search-input {
		background-color: $content-background;
		border: 1px solid $color-brand;
		border-width: 0 0 0 1px;
		padding: 5px;
		position: absolute;
		right: 0;
		top: 9px;
		z-index: 99;

		i {
			color: $color-brand;
			font-size: 30px;
			position: absolute;
			right: 8px;
			top: 9px;
		}

		input {
			@include placeholder {
				color: $color-brand;
			}

			background-color: $content-background;
			border: none;
			color: $color-brand;
			font-size: 22px;
			padding: 8px 40px 8px 10px;
            width: 165px;
		}
	}

	ul {
		@extend %list-reset;

		overflow: hidden;
	}
}


body.home {
	.menu-top {
		box-shadow: none;
		margin-bottom: $grid-gutter*2;
	}
}
﻿.some {
    display: flex;
    flex-direction: row-reverse;
    margin-left: -4px;
    margin-right: -4px;

    @include max-res2 {
        margin-bottom: 15px;
    }

    :where(.vacancies-listing__header) & {
        margin-top: 16px;
        margin-bottom: 0;
    }

    &__share-label {
        @include transition(transform 300ms ease);
        font-weight: bold;
        align-self: center;
        cursor: pointer;
        font-size: 15px;
        transform: translateX(160px) translatex(-5px);

        &--extended {
            transform: translateX(-5px);
        }
    }

    &__item {
        @include transition(transform 300ms ease, opacity 200ms ease);
        margin-left: -4px;
        margin-right: -4px;
        opacity: 0;

        .some__link {
            @include transition(background-color 200ms ease, color 200ms ease);
            padding: 0;
            background-color: transparent;
            border-radius: 100%;
            cursor: pointer;
            border: 2px solid black;
            display: block;
            height: 32px;
            margin: 8px;
            text-align: center;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none !important;

            span {
                color: black;
                line-height: 28px;
            }

            &:hover,
            &.hover {
                background-color: black;

                span {
                    color: white;
                }
            }
        }

        &--share {
            z-index: 1;

            &.active {
                button {
                    span {
                        &:before,
                        &:after {
                            background-color: white;
                            content: "";
                            display: block;
                            height: 20px;
                            left: 50%;
                            position: absolute;
                            top: 50%;
                            width: 3px;
                            transition: background-color 200ms ease-in;
                        }

                        &:before {
                            transform: translate(-50%, -50%) rotate(45deg);
                        }

                        &:after {
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }
                    }
                }

                &:hover, .hover {
                    span {
                        &:before, &:after {
                            background-color: black;
                        }
                    }
                }
            }
        }

        &.visible {
            opacity: 1;
            display: block;
            transform: translateX(0);
        }

        &.active {
            .some__link {
                background-color: black;

                span {
                    color: white;
                }

                &:hover,
                &.hover {
                    background-color: transparent;

                    span {
                        color: black;
                    }
                }
            }
        }
    }

    @for $i from 1 through 6 {
        .some__item:not(.visible):nth-child(#{$i}) {
            transform: translateX( calc(40px * (#{$i} - 1)) );
        }
    }
}

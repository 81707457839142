﻿.article {
  .extranet-logout,
  .author,
  .date {
    color: $font-color-light;
    font-size: 13px;
    margin: 0;
    text-align: right;

    a {
      color: red;
    }
  }

  .extranet-logout {
    padding: 2px 0 2px 0;

    .fa-lock {
      padding-right: 5px;
    }
  }

  .bodyfield {
    clear: both;

    @media (min-width: $breakpoint) {
      clear: none;
    }

    &.pageedit {
      overflow: hidden;
      overflow-wrap: break-word;

      .intro-image {
        width: 100%;
      }
    }

    &,
    p {
      font-size: 15px;
      line-height: 18.4px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      span {
        font-size: inherit;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }

    span {
      font-size: 15px;
    }
  }

  .content {
    overflow: hidden;

    a {
      text-decoration: underline;
    }

    a:not([href]) {
      text-decoration: none;
      color: $font-color-main;
      cursor: text;
    }

    ul.links {
      @extend %list-reset;
      margin: 15px 15px 0 0;
      font-size: 22px;
      display: table;

      a {
        color: $font-color-main;
        display: inline-block;
        font-family: $font-family-headline;
        line-height: 1.35;
        position: relative;
        text-decoration: none;
        padding-left: 1.9em;

        &:before {
          background-color: #ffffff;
          content: "\e807";
          font-family: "fa";
          font-size: 19px;
          height: 30px;
          left: 0;
          margin: auto;
          padding: 3px 9px 4px 11px;
          position: absolute;
          top: 0;
        }

        p {
          display: block;
        }

        p,
        span {
          vertical-align: middle;
        }

        span {
          display: inline-block;

          &.links {
            font-size: 1em;
            -moz-transition-duration: 200ms;
            -o-transition-duration: 200ms;
            -webkit-transition-duration: 200ms;
            transition-duration: 200ms;
          }
        }

        @include aaa {
          span.links {
            color: $color-anchor-hover;
          }
        }
      }

      li {
        display: block;
        position: relative;
      }
    }

    .richtext a.speciallink {
      margin: 15px 15px 0 0;
      font-size: 22px;
      display: table;
      color: $font-color-main;
      display: block;
      font-family: $font-family-headline;
      line-height: 1.35;
      position: relative;
      text-decoration: underline;
      padding-left: 1.9em;

      &:before {
        background-color: #ffffff;
        content: "\e804";
        font-family: "entypo";
        font-size: 19px;
        height: 30px;
        left: 0;
        margin: auto;
        padding: 3px 9px 4px 11px;
        position: absolute;
        top: 0;
      }

      p {
        display: block;
      }

      p,
      span {
        vertical-align: middle;
      }

      span {
        display: inline;

        &.links {
          font-size: 1em;
          -moz-transition-duration: 200ms;
          -o-transition-duration: 200ms;
          -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
          text-decoration: underline;
        }

        @include aaa {
          span.links {
            color: $color-anchor-hover;
          }
        }
      }
    }

    p a {
      text-decoration: underline;
    }

    p a:not([href]) {
      text-decoration: none;
      color: $font-color-main;
      cursor: text;
    }

    .selvbetjening {
      ul {
        margin: 0 !important;
        padding-left: 0;

        li {
          margin: 0;
        }
      }
    }

    ul {
      font-size: 15px;
      padding-left: 35px;

      li {
        margin: 0.5em 0;
      }

      &:not(.search-results, .links) {
        margin: 20px;
      }
    }
  }

  figcaption {
    font-size: 13px;
    text-align: right;
  }

  figure {
    margin: 30px 0;
  }

  .float-right {
    margin-left: 10px;
    margin-right: 0;
  }

  h1,
  h2,
  h3 {
    margin-top: 0;
  }

  .intro-image {
    @media (min-width: $breakpoint) {
      padding-right: $grid-gutter;
      width: calc(58.33333% - 10px);
    }

    figure {
      margin-top: 0;
    }

    figcaption {
      text-align: left;
    }
  }

  .meta {
    margin-top: 75px;
  }

  .nofigure {
    margin-top: 20px;
  }

  .selvbetjening,
  .selvbetjening-cta {
    a {
      text-decoration: none;
    }
  }

  .title {
    margin-top: 0;
    padding: 0;

    @media (min-width: $breakpoint) {
      padding-top: 11px;
    }
  }

  .title-tender {
    @media (min-width: $breakpoint) {
      padding-top: 0px;
    }
  }

  .footerspots {
    background-color: #ffffff;
  }

  .pexpenses {
    overflow-x: auto;

    $m-color: #cfcfcf;

    &-years {
      width: 100px;
      margin: 5px 0 10px 0;
    }

    &-title {
      display: none;
      margin: 1em 0 0;

      @include max-res2 {
        &.m-show {
          display: block;
        }
      }
    }

    &-table {
      border: 1px;
      border-style: solid;
      margin-top: 25px;
      width: 100%;
      border-spacing: 0;

      @include max-res2 {
        margin-top: 0;
        border: 0;
      }

      thead {
        @include max-res2 {
          display: none;
        }
      }

      tr {
        @include max-res2 {
          margin: 0;
          display: block;

          &:after {
            content: "";
            display: table;
            clear: both;
          }
        }
      }

      th,
      td {
        border: 1px;
        border-style: solid;
        text-align: left;
        padding: 5px;
      }

      td {
        @include max-res2 {
          box-sizing: border-box;
          display: block;
          float: left;
          clear: left;
          width: 100%;
          text-align: right;
          font-size: 13px;
          border: 0;
          border-bottom: 1px solid $m-color;

          &.m-hide {
            display: none;
          }

          &.m-show {
            border-left: 1px solid $m-color;
            border-right: 1px solid $m-color;
          }

          &:first-child {
            background-color: $m-color;
            color: $font-color-main;
            margin-top: 1.5em;
            border: 1px solid $m-color;
            font-weight: 600;
            text-align: left;

            &:before {
              display: none;
            }

            &:after {
              content: attr(data-label);
              float: right;
              //text-transform: uppercase;
              //font-weight: bold;
            }
          }

          &:before {
            content: attr(data-label);
            float: left;
            //text-transform: uppercase;
            //font-weight: bold;
          }
        }
      }
    }

    &-cat {
      min-width: 300px;
    }

    &-month {
      min-width: 50px;
    }
  }

  .citizencouncil {
    .author,
    .date,
    .by {
      color: #6c6c6a;
      font-size: 13px;
      /*margin: 0;*/
    }

    .citizencouncil-idea {
      margin-top: 20px;
    }

    .citizencouncil-idea-comment {
      margin-top: 10px;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0px;
      }

      .richtext {
        white-space: pre-line;
      }
    }

    .citizencouncil-idea-answer {
      padding-top: 10px;
      padding-left: 15px;
      position: relative;
      /*&:before {
                content: '\f148';
                position: absolute;
                left: -12px;
                font-family: FontAwesome;
                font-size: 20px;
                transform: rotate(90deg);
                color: #6c6c6a;
            }*/
      .answer-from {
        background: #072a63;
        padding: 3px 8px 3px 29px;
        border-radius: 3px;
        color: #ffffff;
        margin-bottom: 14px;

        &:before {
          content: "\f148";
          position: absolute;
          font-family: FontAwesome;
          font-size: 20px;
          transform: rotate(90deg);
          color: #ffffff;
          left: 22px;
        }
      }

      .richtext {
        margin-top: 4px;
      }
    }

    .citizencouncil-idea-add-comment {
      display: none;
    }

    .citizencouncil submit {
      margin-left: 66px;
    }

    label {
      color: #6c6c6a;
      cursor: pointer;
      font-size: 13px;
      margin-bottom: 2px;
      padding-top: 5px;
      float: left;
      width: 80px;
      overflow-wrap: break-word;
      /*text-transform: uppercase;*/
    }

    input[type="submit"] {
      margin-left: 80px;
      width: auto;
      margin-top: -4px;
      background-color: #36864a;
      color: #fff;
      display: inline-block;
      font-family: "oswaldlight", Arial, sans-serif;
      font-size: 19px;
      padding: 12px 30px 9px 30px;
      position: relative;
      border: none;

      &:disabled {
        cursor: not-allowed;
      }
    }

    input,
    textarea {
      border: 1px solid #6c6c6a;
      padding: 5px;
      transition: background-color 300ms ease, border-color 300ms ease;
      width: calc(100% - 80px);
      margin-bottom: 10px;

      &:focus {
        border-color: $color-border;
      }

      &.error {
        background-color: #ffbfbf;
      }
    }

    textarea {
      height: 100px;
    }

    .citizencouncil-idea-add-comment {
      .col-md-10 {
      }

      .g-recaptcha {
        margin-left: 80px;
        margin-bottom: 15px;
      }
    }

    .citizencouncil-idea-add-comment-show {
      border: none;
      float: right;
      margin-top: -31px;
      cursor: pointer;
      background-color: #36864a;
      color: #fff;
      display: inline-block;
      font-family: "oswaldlight", Arial, sans-serif;
      font-size: 19px;
      padding: 12px 30px 9px 30px;
      position: relative;
    }

    .citizencouncil-idea-comments {
      background: #fff;
      padding: 10px;
      margin-top: 10px;
      position: relative;
      overflow: hidden;

      .fade {
        display: none;
        position: absolute;
        top: 50px;
        background: rgb(2, 0, 36);
        background: linear-gradient(
          180deg,
          rgba(2, 0, 36, 0) 0%,
          rgba(9, 9, 121, 0) 33%,
          rgba(255, 255, 255, 1) 89%,
          rgba(255, 255, 255, 1) 100%
        );
        bottom: 0px;
        left: 0;
        right: 0;
        text-align: center;
        text-decoration: underline;
        transition: opacity 0.2s;
        z-index: 1;
      }

      .expand {
        display: none;
        position: absolute;
        bottom: 0;
        text-align: center;
        left: 0;
        right: 0;
        padding-bottom: 10px;
        text-decoration: underline;
        margin-bottom: -10px;
        transition: opacity 0.2s;
        cursor: pointer;
        z-index: 1;
      }

      &.contracted {
        max-height: 300px;

        &.contracted--loading {
          .expand,
          .fade {
            opacity: 0;
          }
        }

        .fade,
        .expand {
          display: block;
          opacity: 1;
        }
      }
    }

    .citizencouncil-idea-comments h4 {
      margin-top: 0px;
      padding-top: 0px;
    }
  }
  /* remove margin on seperator between section */
  table.styles_canvas {
    .styles_block_margin_top {
      margin: 0px;
    }
  }
}
/**/

﻿header {
    background-color: $color-brand;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.25);
    position: relative;

    @media (min-width: $breakpoint) {
        background-color: transparent;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.10);
    }

    .logo {
        box-sizing: content-box;
        display: block;
        height: 50px;
        left: 12px;
        padding-bottom: 9px;
        padding-top: 11px;
        position: absolute;
        z-index: 99;

        &--hide-desktop {
            @media (min-width: $breakpoint) {
                display: none !important;
            }
        }

        @media (min-width: $breakpoint) {
            display: none;
            left: auto;
            padding: 0;
            /*top: 0;*/
        }
    }

    @media (max-width: $breakpoint) {
        .logo {
            width: 154px;
            height: auto;
        }
    }
}

body.home {
    header {
        @media (min-width: $breakpoint) {
            box-shadow: none;

            .logo {
                background-color: $color-brand;
                display: block;
                height: auto;
                /*padding: 5px 30px 15px 30px;*/
                top: 0;
            }
        }
    }
}

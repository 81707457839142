.first-agenda {
    &--0 {
        padding-top: 2em;
    }

    &__body {
        padding-top: 1em;
    }

    &__title {
        font-size: 55px;
    }

    ul,
    ol {
        margin: 20px 0;
        font-size: 15px;
        /*padding-left: 25px;*/
        li {
            margin: 0.5em 0;
        }
    }

    &__section-list {
        list-style-type: none;
        padding-left: 0px;
    }

    &__overview-list {
        margin-left: 0 !important;

        a {
            text-decoration: none !important;

            span {
                display: inline-block !important;
            }
        }
    }

    &__loader {
        p {
            margin-bottom: 5px;
            margin-left: -12px;
        }

        display: block;
        font-size: 0px;
        text-align: center;
        margin-top: 80px;
        padding: 0px;

        span {
            vertical-align: middle;
            border-radius: 100%;
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 3px 2px;
            -webkit-animation: loader 0.8s linear infinite alternate;
            animation: loader 0.8s linear infinite alternate;

            &:nth-child(1) {
                animation-delay: -1s;
                background: rgba(108, 108, 106, 0.6);
            }

            &:nth-child(2) {
                animation-delay: -0.8s;
                background: rgba(108, 108, 106, 0.8);
            }

            &:nth-child(3) {
                animation-delay: -0.26666s;
                background: rgba(108, 108, 106, 1);
            }

            &:nth-child(4) {
                animation-delay: -0.8s;
                background: rgba(108, 108, 106, 0.8);
            }

            &:nth-child(5) {
                animation-delay: -1s;
                background: rgba(108, 108, 106, 0.4);
            }
        }
    }

    @keyframes loader {
        from {
            transform: scale(0, 0);
        }

        to {
            transform: scale(1, 1);
        }
    }

    @-webkit-keyframes loader {
        from {
            -webkit-transform: scale(0, 0);
        }

        to {
            -webkit-transform: scale(1, 1);
        }
    }
}

﻿.googlesearch {
    .google-navigation {
        margin: $font-size-base*1.75 0 $font-size-base -5px;

        &,
        .google-navigation2 {
            float: none;
            width: 100%;
        }

        .google-navigation2 {
            overflow: hidden;

            @media (min-width: $breakpoint) {
                height: 27px;
            }

            div {
                display: none;

                    &:not([class*="noimg"]) {
                        display: block;
                    }
            }
        }
    
        .google-navigation-pagenum {
            margin: 0 0 5px 5px;
            width: auto;

            a {
                background-color: #FFFFFF;
                text-decoration: none;
            }

            a,
            span {
                display: block;
                padding: $font-size-base*0.25 $font-size-base*0.75;
            }

            br,
            img {
                display: none;
            }

            span {
                background-color: $color-brand;
                color: #FFFFFF;

                &.i {
                    font-weight: normal;
                }
            }
        }
    }
}
.nav-search {
    margin-left: -11px;
    margin-right: -22px;
    height: 100%;
    padding: 0 22px;

    @include max-res1 {
        padding-left: 0;
    }

    &__button {
        @include transition(background-color 200ms ease);

        @include max-res1 {
            background-color: transparent;
            padding-top: 15px;
            padding-bottom: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        background-color: darken($color: $color-brand, $amount: 10%);
        border: none;
        color: #fff;
        font-family: $font-family-headline;
        font-size: 18.7px;
        font-weight: bold;
        height: 100%;
        margin: 0;
        outline: none;
        padding: 0 20px;

        @include aaa {
            background-color: darken($color: $color-brand, $amount: 15%);
        }

        span.fa {
            color: #fff;
            font-size: 20px;
            padding-right: 5px;

            @include max-res1 {
                padding: 0;
                margin-bottom: 8px;
            }
        }
    }

    &__search-button {
        @include transition(background-color 200ms ease);
        @include transition(color 200ms ease);
        @include transition(border-color 200ms ease);
        border: 1px solid transparent;
        border-left: none;
        background-color: #fff;
        color: $color-brand;
        font-family: $font-family-headline;
        font-size: 18.7px;
        font-weight: bold;
        height: 50px;
        margin: 0;
        outline: none;
        padding: 0 20px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        @include aaa {
            outline: none;
            color: #fff;
            background-color: $color-brand;
        }
    }

    &__container {
        @include max-res1 {
            left: 10px;
            right: 10px;
        }

        left: 0;
        right: 0;
        position: absolute;
        top: 100%;
        z-index: 200;
        margin-top: 10px;
    }

    &__field {
        @include transition(border-color 200ms ease);
        -webkit-appearance: none !important;
        border: 1px solid transparent;
        border-right: none;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 50px;
        width: 100%;
        font-size: 20px;
        padding: 0 20px;
        flex-grow: 1;

        &--suggestions {
            border-color: #80808054;

            & + .nav-search__search-button {
                border-color: #80808054;
            }
        }

        &:focus {
        
        outline-color: $color-brand;
        }

        &--suggestions {
            border-bottom-left-radius: 0;

            & + .nav-search__search-button {
                border-bottom-right-radius: 0;
            }
        }

        &-container {
            display: flex;
        }
    }

    &__suggestion {
        padding: 10px 20px;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid #80808054;
        }

        &--highlight, &:hover {
            background-color: $color-brand;
            color: white;
        }
    }

    &__suggestions {
        @extend %list-reset;
        border: 1px solid #80808054;
        border-top: none;
        background: white;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

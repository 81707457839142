﻿@font-face {
    font-display: swap;
    font-family: 'oswaldbold';
    src: url('fonts/Oswald-Bold-webfont.eot');
    src: url('fonts/Oswald-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/Oswald-Bold-webfont.woff2') format('woff2'), url('fonts/Oswald-Bold-webfont.woff') format('woff'), url('fonts/Oswald-Bold-webfont.ttf') format('truetype'), url('fonts/Oswald-Bold-webfont.svg#oswaldbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'oswaldlight';
    src: url('fonts/Oswald-Light-webfont.eot');
    src: url('fonts/Oswald-Light-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/Oswald-Light-webfont.woff2') format('woff2'), url('fonts/Oswald-Light-webfont.woff') format('woff'), url('fonts/Oswald-Light-webfont.ttf') format('truetype'), url('fonts/Oswald-Light-webfont.svg#oswaldlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'oswald_regularregular';
    src: url('fonts/Oswald-Regular-webfont.eot');
    src: url('fonts/Oswald-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/Oswald-Regular-webfont.woff2') format('woff2'), url('fonts/Oswald-Regular-webfont.woff') format('woff'), url('fonts/Oswald-Regular-webfont.ttf') format('truetype'), url('fonts/Oswald-Regular-webfont.svg#oswald_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'oswaldregular';
    src: url('fonts/Oswald-RegularItalic-webfont.eot');
    src: url('fonts/Oswald-RegularItalic-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/Oswald-RegularItalic-webfont.woff2') format('woff2'), url('fonts/Oswald-RegularItalic-webfont.woff') format('woff'), url('fonts/Oswald-RegularItalic-webfont.ttf') format('truetype'), url('fonts/Oswald-RegularItalic-webfont.svg#oswaldregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
.tenders {
  .richtext {
    padding-bottom: 10px;
  }

  &__table {
    table-layout: fixed;
    @media (max-width: 349px) {
      width: 100%;
    }
    max-width: 100%;

    td {
      //   padding-bottom: 25px;
      padding-bottom: 15px;
      vertical-align: top;
      word-break: break-word;
    }
  }

  &__overview {
    td {
      padding-top: 19px;
      padding-bottom: 0;
    }
  }

  &__month {
    .tenders & {
      background-color: #ffdf00;
      display: inline-block;
      font-size: 23px;
      margin: 20px 0 0;
      padding: 8px 8px 5px;
    }
  }

  a.tenders__link,
  span.tenders__link {
    text-decoration: none;
    // margin-bottom: 25px;
    // @media (min-width: $breakpoint) {
    //   //   margin-bottom: 12px;
    //   overflow: hidden;
    // }

    &--date {
      margin-bottom: 5px;
      background-color: #ffffff;
      color: $font-color-main;
      font-size: 14px;
      margin-right: 10px;
      padding: 4px 9px;
      @media (min-width: 350px) {
        white-space: nowrap;
      }
    }

    &--title {
      display: block;
      //   margin-top: 10px;
      margin-left: 1px;

      @media (min-width: $breakpoint) {
        display: inline-block;
        // margin-top: 3px;
        margin-left: 0px;
        padding-top: 0px;
      }
    }
  }
}

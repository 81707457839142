﻿.frise {
  margin-bottom: 40px;

  &.has-image {
    img {
      float: right;
      margin: 0 0 15px 15px;
      width: 45%;
    }
  }

  a {
    &:hover,
    &:focus {
      h2 {
        border-color: $font-color-main;

        .link {
          border-color: $font-color-main;
        }
      }
    }
    .equal-frise-text .underline {
      border-color: $font-color-main;
    }
  }

  .equal-frise {
    display: block;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    // min-height: 35px;

    @media (min-width: $breakpoint) {
      border-bottom: 2px solid #cccccc;
      margin-bottom: 15px;
      min-height: 35px;

      &::after {
        bottom: 0;
        color: #cccccc;
        content: "\e804";
        font-family: "entypo";
        font-size: 30px;
        font-weight: normal;
        margin-bottom: -17px;
        margin-right: 0;
        position: absolute;
        right: 0;
      }
    }
  }

  .underline {
    @media (max-width: $breakpoint) {
      border-color: $font-color-main;
    }

    @include transition(border-color 200ms ease);
    border-bottom: 1px solid transparent;
  }

  h2 {
    color: $font-color-main;
    display: block;
    font-size: 22px;
    margin: 0;
    padding-top: 15px;
    position: relative;

    @media (min-width: $breakpoint) {
      display: inline;
      position: absolute;
      padding-bottom: 11px;
      padding-top: 0;
      bottom: 0;
    }
  }

  .link {
    @include transition(border-color 200ms ease);
    border-bottom: 1px solid transparent;
  }

  p {
    color: #545454;
    font-size: 15px;
    margin: 0;
  }
}

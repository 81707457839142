.subsite-intro {
    position: relative;
    display: grid;
    &.subsite-intro--headline-sub{
        grid-template-areas: "title title" "teaser teaser";    
        margin-bottom: 30px;
        h2{
            margin-top: 0;
        }
    }
    grid-template-areas: "title some" "teaser teaser";
    align-items: flex-start;
    grid-template-columns: 1fr auto;

    @include max-res2 {
        grid-template-areas: "some some" "title title" "teaser teaser";
    }

    :where(.first-agenda) & {
        grid-template-areas: "some some" "title title" "teaser teaser";
    }

    .title {
        grid-area: title;
    }

    .some {
        grid-area: some;
    }

    .teaser {
        grid-area: teaser;
    }

    &.news {
        grid-template-areas: "tag some" "title title" "date date" "teaser teaser";

        .share {
            top: 50px;
        }

        .some {
            margin-bottom: 10px;
        }
    }

    .date {
        background-color: #FFFFFF;
        display: inline-block;
        font-family: $font-family-main;
        font-size: 14px;
        font-weight: normal;
        padding: 4px 9px;
        color: #585858;
        grid-area: date;
        justify-self: flex-start;
    }

    .tenderdate {
        color: #585858;
        background-color: #FFFFFF;
        display: inline-block;
        font-family: $font-family-main;
        font-size: 14px;
        font-weight: normal;
        padding: 4px 9px;
        margin-bottom: 5px;
    }

    h1:not(.first-agenda__title) {
        font-size: 32px;
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 0;
        padding-right: 5px;

        @media (min-width: $breakpoint) {
            font-size: 55px;
        }

        :where(.vacancies-listing__header) & {
            @media (min-width: $breakpoint) {
                margin-top: 0 !important;
            }
        }
    }

    h2 {
        font-family: 'oswald_regularregular';
        font-size: 24px;

        @media (min-width: $breakpoint) {
            font-size: 24px;
        }
    }

    li {
        background-color: #FFFFFF;
        display: inline-block;
        float: left;
        margin-left: 5px;
    }

    .news-intro {
        background-color: #FFDF00;
        display: inline-block;
        font-family: $font-family-headline;
        font-size: 23px;
        margin-bottom: 10px;
        padding: 8px 8px 5px;
        grid-area: tag;
        justify-self: flex-start;
        align-self: center;
    }

    p {
        font-family: $font-family-headline;
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 25px;

        @media (min-width: $breakpoint) {
            font-size: 25px;
        }
    }

    .share {
        @extend %list-reset;
        @extend %show-desktop-only;
        position: absolute;
        right: 0;
        top: 20px;

        a {
            color: $font-color-secondary;
            display: table;
            height: 35px;
            text-decoration: none;
            width: 35px;

            &:hover {
                color: $color-brand;
            }
        }

        i {
            display: table-cell;
            font-size: 15px;
            text-align: center;
            vertical-align: middle;
        }
    }
}

.sectionsubpage {
    .subsite-intro {
        padding: 0 $grid-gutter;
    }
}

.vacancies-listing__description{
    h2{
font-family: 'oswald_regularregular';
        font-size: 24px;

        @media (min-width: $breakpoint) {
            font-size: 24px;
        }
    }}

﻿.mobile-buttons {
	@extend %show-mobile-only;

	background-color: $color-brand;
	padding: 10px 5px 7px;

	div {
		color: #FFFFFF;
		font-family: $font-family-headline;
		
		div,
		.mobile-buttons-icon,
		span:not(.mobile-buttons-icon) {
			display: block;
		}

		.mobile-buttons-icon {
			font-size: 32px;
		}

		.mobile-buttons-icon,
		span:not(.mobile-buttons-icon) {
			text-align: center;
		}
		span:not(.mobile-buttons-icon) {
			font-size: 14px;
			padding-top: 6px;
			text-transform: uppercase;
            font-weight:bold;
		}
	}

	.container {
		overflow: hidden;
	}

	li {
		display: inline-block;
		margin: 0 10px;
		float: left;
	}

	.search-input {
		@include transform(translateX($mobile-search-transform));
		@include transition(transform 300ms ease);

		position: absolute;
		right: 9px;
		top: 10px;
		z-index: 99;

		&.active {
			@include transform(translateX(0));
			@include transform(translate3d(0, 0, 0));
		}

		i {
			color: $color-brand;
			font-size: 30px;
			position: absolute;
			right: 8px;
			top: 10px;
		}

		input {
			@include placeholder {
				color: $color-brand;
			}

			background-color: $content-background;
			border: none;
			color: $color-brand;
			font-size: 20px;
			padding: 14px 45px 14px 10px;
			width: $mobile-search-width;
		}
	}

    .search-input.hide-mobile-search {
        display:none;
    }

	ul {
		@extend %list-reset;

		float: right;
	}
}
﻿.menuService-mobile {
	// @include transform(translateX(- $menu-mobile-width));
	// @include transform(translate3d(- $menu-mobile-width, 0, 0));
	@include transition(transform 400ms ease);

	-webkit-overflow-scrolling: touch;
	padding-bottom: 20px;
	padding-left: 10px;
	// width: $menu-mobile-width;
	z-index: 98;

	& > ul {
		width: 100%;

		& > li {
			font-family: $font-family-headline;
			font-size: 16px;

			.menu-icon {
				padding: 9px 15px;
			}

			&.active > .submenu.active {
				border-color: $color-brand;
			}

			& > .submenu-toggle {
				border-bottom: 1px solid $color-border;
			}

			& > .submenu-toggle.active {
				& + .submenu.active {
					border-color: $color-brand;
				}
			}
			
			&:first-child {
				& > .submenu-toggle {
					padding-top: 15px;

					i {
						padding-top: 11px;
					}
				}
			}

			&:not(:last-child) {
				.submenu {
					&.active {
						border-color: transparent;
					}
				}
			}

			& > .submenu {
				&.active {
					border-color: $color-border;
				}
			}

			& > ul > li {
				font-size: 15px;

				.menu-icon {
					padding: 7px 15px;
				}

				ul {
					padding-left: 10px;
				}
			} 
		}
	}

	li {
		font-family: $font-family-main;
		font-weight: normal;
	}

	.menuService-mobile-service {
		margin-top: 15px;

		a {
			color: #082561;
			display: block;
			padding: 3px 0;

			&:focus {
				outline: none;
				border: 1px solid #000;
			}
		}
		li {
			font-family: $font-family-main;
			font-size: 14px;
			font-weight: normal;
		}
	}

	.submenu {
		@include transition(border-color 200ms ease);

		border-bottom: 1px solid transparent;
		display: none;

		a {
			display: block;
			padding: 8px 58px 8px 0;
			position: relative;

			&:focus {
				outline: none;
				border: 1px solid #000;
			}
		}
	}

	.submenu-toggle {
		display: block;
		overflow: hidden;
		padding: 12px 10px 6px 0;
		position: relative;

		&.active {
			.menu-icon:before {
				content: '\e803';
			}
		}

		.menu-icon {
			color: $font-color-main;
		}
	}

	ul {
		@extend %list-reset;

		.menu-icon {
			border: none;
			background: transparent;
			/*border-left: 1px solid $color-border;*/
			color: transparent;
			font-size: 16px;
			position: absolute;
			right: 0;
			top: 0;

			&:focus {
				outline: none;
				border: 1px solid #000;
			}
		}
		ul {
			.submenu-toggle {
				&.active {
					span:not(.menu-icon) {
						background-color: #FFFFFF;
						border-radius: 3px;
						display: block;
						padding: 3px 0;
					}
				}
			}
		}
	}

        /*.mobile-translate-box {
        position: absolute;
        z-index: 100;
        width: 205px;
        background: #ffffff;
        border: 1px solid #b5b5b5;
        left: -899em;
        padding: 1em;
        top: 30px;
        float: inherit;
        overflow: visible;
    }

    .translate-active {
        left: 718px;
    }

    #mobile-translate-close {
        width: 100%;
        text-align: right;
        cursor: pointer;
        padding-bottom: 5px;
    }*/
}


.show-menu {
	.menuService-mobile {
		// @include transform(translateX(0));
		// @include transform(translate3d(0, 0, 0));
	}
}
﻿.canvas {
	@include transform(translateX(0));
	@include transform(translate3d(0, 0, 0));
	@include transition(transform 300ms ease);

	box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.15);
	min-height: 100vh;
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;
	position: relative;
	z-index: 99;

	@media (min-width: $breakpoint) {
		box-shadow: none;
	}
}


.show-menu {
	// 
}
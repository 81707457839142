﻿body:not(.no-table-formatting) {
    .content table:not([class]),
    .content table[class=''],
    .base-table-with-head,
    .base-table-with-head-and-foot {
        border-collapse: collapse;
        border-spacing: 0;
        display: inline-block;
        font-size: 0.875rem;
        max-width: 100%;
        overflow-x: auto;
        vertical-align: top;
        width: 100%;

        caption {
            padding: 2px 0;
            text-align: left;

            @media screen and (min-width: 768px) {
                text-align: center;
            }
        }

        th,
        td {
            vertical-align: top;
            text-align: left;
            padding: 0.625rem 1.25rem;
            border-width: 1px;
            border-style: solid;
            border-color: $content-background;

            a:not([href]) {
                color: #fff !important;
            }
        }

        th {
            font-family: $font-family-headline;
            font-weight: normal;
            line-height: 1.5;
            font-size: 1rem;
        }

        thead {
            th {
                font-size: 1.25rem;
                color: $color-table-text-light;
            }

            tr {
                background-color: $color-table-head;
            }
        }

        tbody {
            overflow: scroll;

            th {
                background-color: $color-table-head;
                color: $color-table-text-light;
            }

            tr {
                &:nth-child(even) {
                    td {
                        background-color: rgba(255, 255, 255, 0.7);
                    }
                }

                &:nth-child(odd) {
                    td {
                        background-color: #fff;
                    }
                }

                th {
                    background-color: $color-table-head--secondary;
                }
            }

            &:first-child {
                th:first-child {
                    background-color: $color-table-head;
                }

                tr {
                    &:nth-child(even) {
                        td {
                            background-color: #fff;
                        }

                        th:first-child {
                            ~ td {
                                background-color: rgba(255, 255, 255, 0.7);
                                color: $color-table-text-dark;
                                font-family: $font-family-main;
                                font-size: 0.875rem;
                            }
                        }
                    }

                    &:nth-child(odd) {
                        td {
                            background-color: rgba(255, 255, 255, 0.7);
                        }

                        th:first-child {
                            ~ td {
                                background-color: #fff;
                                color: $color-table-text-dark;
                                font-family: $font-family-main;
                                font-size: 0.875rem;
                            }
                        }
                    }

                    &:first-child {
                        td {
                            background-color: $color-table-head;
                            font-family: $font-family-headline;
                            line-height: 1.5;
                            font-size: 1.25rem;
                        }

                        color: $color-table-text-light;
                    }
                }
            }
        }
    }

    .base-table-with-head-and-foot {
        // tfoot
        tbody {
            tr:last-child {
                background: $color-table-head--secondary !important;
                color: $color-table-text-light;
            }
        }
    }
}

﻿div.ditmer-pubdata {
    font-size: 14px;
    background: #fff;
    padding: 25px;
    margin: 5px;
    border: gray 2px solid;
}

#xmlMenuTree > div > ul {
    > ul {
        padding-bottom: 5px;
    }

    > span {
        display: block;
        padding-bottom: 7px;
    }
}


.homeIcon {
    padding-right: 5px;
}


/*#xmlMenuTree > div ul {
    > span > a {
        color: #666666;
    }

    > li > span > a {
        color: #666666;
    }
}*/

#xmlMenuTree > div > ul ul > span > a {
    line-height: 18px;
}

div.dagsordenIframe {
    position: relative;
    padding-bottom: 85%;
    padding-top: 30px;
    height: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-top: rgb(102, 102, 102) solid 1px;
    border-bottom: rgb(102, 102, 102) solid 1px;
}

@media (max-width: 650px) {
    #canvas article > div.content > h1::after {
        content: "\A Bemærk at skemaer og diagrammer kun kan vises når du holder din telefon vandret!";
        white-space: pre-wrap;
    }
}

div.dagsordenIframe > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3000cm;
    border: none;
}

#canvas div.content > div.service {
    padding: 0;
    margin: 0;

    > a {
        color: #4f4f4f;
        font-size: 10px;
    }
}

#xmlMenuTree > div > ul > ul > ul > span > a {
    font-size: 14px;
}

﻿.video-container {
    margin: 0 10px 10px auto;
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 */
    position: relative;

    embed,
    iframe,
    object {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        border: none;
    }
}
.iframe-container {
    margin: 0 10px 10px auto;

    embed,
    iframe,
    object {
        width: 100%;
        border: none;
    }
}
.video-disclaimer {
    max-width:400px;
}
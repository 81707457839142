﻿.rte-facts {
    border-left: 3px #000000 solid;
    background-color: #e1ddd6;
    position: relative;
    margin: 1.5em 0;

    &__heading {
        padding: 20px 20px 0;
        text-transform: uppercase;
        margin-bottom: 0.5em;

        h2 {
            font-size: 1em;
            font-family: $font-family-main;
            font-weight: bold;
            padding: 0;
            margin: 0;
        }

        ~ * {
            @media (min-width: 768px) {
                padding: 0 20px !important;
            }

            &:last-child {
                @media (min-width: 768px) {
                    padding-bottom: 20px !important;
                }
            }
        }
    }

    input,
    label {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: 768px) {
            display: none;
        }
    }

    ul {
        padding: 0 20px 20px;
    }

    &__content:not(li) {
        padding: 0 20px 20px;
    }

    input {
        visibility: hidden;

        &:checked {
            + label {
                ~ * {
                    padding: 0 20px;

                    &:last-child {
                        padding-bottom: 20px;
                    }
                }

                .fa {
                    &::before {
                        content: "\f068";
                    }
                }
            }
        }

        &:not(:checked) {
            + label {
                ~ * {
                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                + * {
                    @media (max-width: 767px) {
                        display: block;
                        max-height: calc(1.22em * 2 + 0.5em);
                        overflow: hidden;
                        padding: 0 20px 20px;
                    }
                }
            }
        }

        + label {
            @media (max-width: 767px) {
                background: linear-gradient( rgba(243, 240, 239, 0), rgb(225, 221, 214) );
                display: flex;
                width: 100%;
                justify-content: center;
                height: calc(14px * 3);
            }

            .fa {
                @media (max-width: 767px) {
                    background: black;
                    height: 28px;
                    width: 28px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: -14px;
                }
            }
        }
    }
}

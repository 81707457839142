﻿.menu-sub {
    @extend %show-desktop-only;
    border-bottom: 1px solid $color-border;
    padding: 10px 0;

    a {
        @include transition(border-color 200ms ease);
        border-bottom: 1px solid transparent;
        font-family: $font-family-headline;
        font-size: 19px;
        line-height: 1.2;

        &.active {
            color: $font-color-main;
            font-weight: bold;
        }

        &:hover {
            border-color: shade($color-border, 10%);
            color: $font-color-main;
        }
    }

    .container {
        display: table;
    }

    &-row {
        display: table-row;
        width: 100%;
        @extend %list-reset;
        overflow: hidden;
    }

    &-item {
        display: table-cell;
        padding: 7px 10px 4px 0;
        width: 25%;
    }
}

﻿.selvbetjening-cta {
  margin-top: 30px;

  a {
    text-decoration: underline;
  }

  a,
  p {
    color: $font-color-secondary;
  }

  .fa-angle-right {
    height: 100%;
  }
}

.cta-link {
  @extend %button-reset;
  background-color: $color-selvbetjening-background;
  color: $color-selvbetjening-text;
  display: inline-block;
  font-family: $font-family-headline;
  font-size: 19px;
  padding: 12px 30px 9px 50px;
  position: relative;
  text-decoration: underline;

  &:before {
    @extend %font-awesome;

    background-color: $font-color-main;
    bottom: 0;
    content: $FA-angle-right;
    font-size: 27px;
    left: 0;
    padding: 0 13px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: $color-selvbetjening-background-hover;
    color: $color-selvbetjening-text;
  }
}

a.cta-link,
p.cta-link {
  color: $color-selvbetjening-text;
}

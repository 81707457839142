﻿%font-awesome {
  font-family: "FontAwesome";
  font-weight: normal;
  position: absolute;
}

%image-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

%list-reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
%button-reset {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
%show-desktop-only {
  display: none;

  @media (min-width: $breakpoint) {
    display: block;
  }
}

%show-mobile-only {
  @media (min-width: $breakpoint) {
    display: none;
  }
}
%reset-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

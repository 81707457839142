﻿.selvbetjening-overview {
	margin-bottom: 20px;

	.content {
		display: none;
	}

	h1 {
        padding: 5px 0 5px 15px;
		position: relative;
		text-transform: none;
		width: 100%;

		&.active {
			i:before {
				content: '\e803' !important;
			}
		}

		span {
			position: absolute;
			right: 10px;
			top:5px;
		}
	}

	h2 {
		border: 1px solid #FFFFFF;
		border-width: 1px 0;
		color: #FFFFFF;
		font-size: 19px;
		margin: 0;
		padding: 5px 0 5px 15px;
		text-transform: uppercase;
	}

	li {
		&:first-child {
			a {
				background: linear-gradient(to bottom, shade(#36864A, 15%) 0, #36864A 10px, #36864A 100%);
				border-top: none;

				&.active,
				&:hover {
					background: none;
					background-color: #187E3D;
				}
			}
		}
	}

	.toggle-overview {
		cursor: pointer;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
﻿.rte-quote{
    padding: 5px 10px;
    border-left: 3px #000000 solid;
    margin-right: 40px;
    &__icon{
        height: 25px;
        width: 22px;
        margin-bottom: 10px;
        img{
            display: block;
        }
    }
    &__content{
        margin: 0;
        font-size: 1.5em;
        line-height: 1;
        cite{
            display: block;
            font-style: normal;
            font-size: .75rem;
            color: #555555;
            margin-top: 1em;
        }
    }
}
﻿#cookieInformerBooklet {
    display: table;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    padding: 1.2em 1.5em;
    color: #ffffff;
    font-size: 18.7px;
    font-weight: bold;

    background-color: $color-cookie-banner;

    a {
        color: #ffffff;
        border-bottom: 1px solid #ffffff;

        &:hover {
            border-bottom: 2px solid #ffffff;
        }
    }

    .cookie {
        &-button, &-text, &-alert {
            display: table-cell;
            vertical-align: middle;
            line-height: 1em;
            margin: 0;
        }

        &-alert {
            img {
                width: 1.2em;
            }
        }

        &-text {
            padding-left: 0.5em;
            display: table-cell;
            padding-right: 1em;
        }

        &-button {
            /*font-size: 90%;*/
            cursor: pointer;
            float: right;
            padding: 0.5em 2em;
            border: 2px solid #ffffff;
            transition-duration: 0.1s;

            &:hover {
                background-color: #ffffff;
                color: $color-brand;
            }
        }
    }
}

﻿@for $i from 1 through 12 {
  .column-#{$i} {
    margin: 10px 0;
    width: 100%;

    @media (min-width: $breakpoint) {
      margin: 0;
      width: 100% / $grid-columns * $i;
    }
  }
}

[class*='column-'] {
  float: left;
  display: inline-block;
  min-height: 1px;
  padding: 0 $grid-gutter;

  &.no-padding {
    padding: 0;
  }

  &.no-padding-left {
    @media (min-width: $breakpoint) {
      padding-left: 0;
    }
  }
  &.no-padding-right {
    @media (min-width: $breakpoint) {
      padding-right: 0;
    }
  }
}

.grid-container {
  margin: 0 auto;
  max-width: $max-width + $grid-gutter * 2;
  position: relative;
  width: 100%;
  + .grid-container {
    margin-top: 2rem;
  }
}

.grid-container,
.row {
  zoom: 1;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

.sectionsubpage {
  .column-9 {
    padding: 0;
  }
}

﻿.job {
    .article.grid-container{
        clear: both;
        &:after, &:before{
            clear: both;
        }
    } 
    .search-vacancies {
        padding: 19px 0 12px 20px;

        .service {
            padding: 0;
        }
    }

    .search-vacancies p {
        margin: 0 0 13px;
    }

    .search-vacancies {
        .form-holder {
            margin: 0 0 0 -5px;
            padding: 0 0 5px;
            list-style: none;
            overflow: hidden;
            display: flex;
        }

        label {
            color: #d9276a;
            text-transform: uppercase;
            float: left;
            padding: 5px 0 2px;
        }

        .input-text {
            float: left;
            clear: left;

            input {
                float: left;
                margin: 0;
            }
        }

        select {
            // float: left;
            // clear: left;
            // width: 155px;
            color: #333;
            font: 13px/14px Verdana, Geneva, Tahoma, sans-serif;
        }
        /*img:focus {
            border: solid 1px #A6C7FF;
    }*/
    }
    /* search-results */
    .search-results {
        padding: 16px 0 8px 20px;

        .form-label {
            float: left;
            // width: 110px;
            color: #d9276a;
            font: bold 11px/14px Verdana, Geneva, Tahoma, sans-serif;
            text-transform: uppercase;
            padding: 20px 0 0;
        }

        .search-vacancies {
            padding-top: 0;

            label {
                margin: 0 0 0 5px;
                color: $font-color-light;
            }

            select {
                // width: 175px;
                font-size: 9px;
                display: inline;
            }

            .form-holder {
                flex-wrap: wrap;
                margin-left: -7.5px;
                margin-right: -7.5px;

                @media (min-width: 768px) {
                    flex-wrap: nowrap;
                }

                &__item {
                    &:last-child {
                        @media (max-width: 767px) {
                            flex-basis: 100%;
                            max-width: 100%;
                        }

                        @media (min-width: 768px) {
                            flex-basis: calc(100% / 2);
                            max-width: calc(100% / 2);
                        }
                    }

                    flex-basis: calc(100% / 2);
                    max-width: calc(100% / 2);
                    padding: 0 7.5px;

                    @media (min-width: 768px) {
                        flex-basis: calc(100% / 4);
                        max-width: calc(100% / 4);
                    }

                    select {
                        width: 100%;
                        text-overflow: ellipsis;
                    }

                    input {
                        -webkit-appearance: none;
                    }
                }
            }

            .jobtxt {
                width: 125px;
                margin: 0 0 0 5px;
                display: inline;
            }

            .jobtxt input {
                width: 232px;
            }

            .jobnum {
                background: url(/images/job/bg-jobnum-res.gif) no-repeat;
                width: 125px;
                margin: 0 4px 0 5px;
                display: inline;

                input {
                    width: 107px;
                }
            }

            .submit-button {
                width: 125px;
                background: url(/images/job/btn-sog-igen.png) no-repeat;
                margin: 8px -14px -3px 5px;
                position: relative;
            }

            .submit-button {
                &:hover,
                &.hover {
                    background: url(/images/job/btn-sog-igen-hover.png) no-repeat;
                }
            }
        }
    }

    .vacancies-listing ul li {
        .title:not(h1) h2 {
            color: #000;
            text-decoration: none;
            font-family: $font-family-headline;
            font-size: 24px;
            line-height: 1.2;
        }

        .description .descript-text p {
            color: #000;
            text-decoration: none;
        }
    }

    .vacancies-listing__link {
        &:hover {
            .title:not(h1) h2 {
                text-decoration: underline !important;
            }
        }

        &:link,
        &:active,
        &:visited {
            .title:not(h1) h2 {
                color: #000;
                text-decoration: none;
            }
        }
    }

    .main-column {
        float: left;
        width: 100%;
        padding: 0 0 0 0;

        @media (min-width: 768px) {
            width: 67%;
        }
    }

    .vacancies-listing {
        margin: 0 0 0 -20px;

        ul {
            margin: 8px 0 0 0;
            padding: 0;
            list-style: none;
            width: 100%;
            /*overflow: hidden;*/
            li {
                float: left;
                width: 100%;
                border-bottom: 1px solid #d1ccbd;

                .title:not(h1) {
                    margin: 0;
                    padding: 18px 0 10px 20px;
                    width: auto;
                    height: 1%;
                }

                .title-extra {
                    text-align: center;
                    display: none;
                }

                &.viewed .title-extra {
                    display: block;
                }

                .description {
                    /*overflow: hidden;*/
                    margin: 1px 0 0px 20px;
                    padding: 1px 0 15px 0px;
                    font-size: 10px;

                    .descript-text {
                        float: left;
                        width: 100%;
                        padding-right: 30px;

                        @media (min-width: 768px) {
                            width: 69%;
                        }

                        p {
                            margin: 0;
                            line-height: 18px;
                            float: left;
                            padding: 0 4px 5px 0;
                        }
                    }

                    .descript-info {
                        float: left;
                        width: 100%;
                        padding: 0 0 15px;

                        @media (min-width: 768px) {
                            width: 200px;
                        }
                    }
                }
            }
        }
    }

    .descript-info {
        dl {
            margin: 0;
            padding: 0 0 1px;
            width: 100%;
            overflow: hidden;
        }

        dl.hide {
            display: none;
        }

        dt {
            color: $font-color-light;
            font: bold 10px/16px Verdana, Geneva, Tahoma, sans-serif;
            font-size: 12px;
            text-transform: uppercase;
            float: left;
            min-width: 60px;
            display: inline-block;
            width: 91px;
        }

        dd {
            margin: 0;
            min-width: 80px;
            float: left;
            text-align: right;
            font-size: 12px;
            display: inline-block;
            width: 103px;
            color: black;
        }
    }

    .content-column,
    .vacancies-listing__facts {
        float: right;
        width: 30%;
        margin: 0 0 -10px;
    }

    .vacancies-listing hr,
    .main-column hr {
        margin: 0;
    }

    .title:not(h1) {
        width: 100%;
        overflow: hidden;
        padding: 26px 0 15px 20px;
        margin: 0 0 0 -20px;
    }

    .vacancies-listing .title:not(h1) h2 {
        margin: 0;
        font: 20px/20px Verdana, Geneva, Tahoma, sans-serif;
    }

    .subsite-intro.vacancies-listing__header {
        h1,
        h2 {
            margin: 18px 0 4.61538px !important;

            @media (min-width: 940px) {
                font-size: 55px;
                width: 80%;
                line-height: 55px;
                font-family: "oswaldlight", Arial, sans-serif;
            }
        }
    }
    /* title-extra */
    .title-extra {
        width: 30%;
        float: right;

        .button {
            margin: 2px 0 0 -4px;
        }
    }

    .yellow-bar {
        height: 1%;
        overflow: hidden;
        background: #ffd81b url(/images/job/bg-ads-number.gif) repeat-x;
        font: 10px/16px Verdana, Geneva, Tahoma, sans-serif;
        text-transform: uppercase;
        margin: 4px 0 0;
        padding: 5px 0 4px 0;
    }

    .skift {
        padding: 5px 5px 4px 10px;

        label {
            float: left;
            padding: 0 6px 0 0;
        }

        select {
            float: left;
            width: 105px;
            text-transform: none;
            color: #333;
            font: 9px/14px Verdana, Geneva, Tahoma, sans-serif;
        }
    }
    /* head */
    .head {
        border-bottom: 1px solid #fff;
        padding: 6px 0 5px 17px;
        background: #c82463 url(/images/job/bg-head.gif) repeat-x;
        color: #fff;
        text-transform: uppercase;

        h2 {
            margin: 0;
            font: bold 11px/14px Verdana, Geneva, Tahoma, sans-serif;
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }
    }

    .search-results {
        padding: 6px 0 8px 0px;

        .form-label {
        }

        .search-vacancies {
            padding: 0;
            color: $font-color-light;

            label {
                margin: 0 0 0 5px;
                font-family: $font-family-headline;
                font-size: 14px;
            }

            select {
                // width: 160px;
                font-size: 16px;
                line-height: 20px;
                padding: 13px 42px 13px 4px;
                height: 50px;
                display: inline;
                border: none;
                margin-bottom: 10px;
                -webkit-appearance: none;
                background: url("/images/Jobportal/selectbox-arrow.png") no-repeat right #ffffff;
                background-position-x: 100%;
            }

            select::-ms-expand {
                display: none;
            }

            .jobtxt {
                width: 125px;
                margin: 0 0 0 5px;
                display: inline;
            }

            .input-text {
                display: block;
                float: none;
                position: relative;

                span {
                    position: absolute;
                    top: 15px;
                    left: 9px;

                    &:before {
                        display: block;
                        font-size: 16px;
                    }
                }
            }

            .input-text input {
                height: 50px;
                font-size: 16px;
                padding: 17px 4px 17px 4px;
                display: inline;
                border: none;
                color: $font-color-main;
                width: 100%;
                background: white;
                padding-left: 32px;
            }
        }
    }

    div.button-hold {
        > a.application-job-link {
            font-family: $font-family-headline;
            font-size: 22px;
            padding-left: 20px;
        }

        > a.application-job-link::before {
            font-family: "entypo";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: none;
            width: 1em;
            text-align: center;
            /* For safety - reset parent styles, that can break glyph codes*/
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            //color: #009CE2;
            content: "\e804";
            background-color: #ffffff;
            padding: 3px;
            padding-left: 10px;
            padding-right: 15px;
            margin-right: 10px;
        }
    }



    .vacancies-listing__description em {
        font-family: "oswaldlight", Arial, sans-serif;
        font-size: 25px;
        line-height: 30px;
        font-style: normal;
    }

    .vacancies-listing__description {
        padding-left: 20px;
        font-family: Arial, sans-serif;
        font-size: 15px;
        line-height: 18.4px;

        h3 {
            font-weight: bold;
        }

        a {
            text-decoration: underline !important;
            color: #000 !important;
        }

        a:hover {
            color: #045279 !important;
        }

        > ul {
            padding-left: 40px;
            padding-bottom: 10px;
        }
    }

    .vacancies-listing__facts {
        margin-top: 25px;
        margin-left: 20px;
        float: left;
        width: 210px;

        > div.spot {
            background-color: #ffffff;
        }

        > div {
            > .spot-cont {
                padding-left: 16px;
                padding-top: 8px;
                padding-bottom: 3px;

                > h2 {
                    border: none;
                }

                > div > div > div {
                    > dl {
                        > dt {
                            font-weight: bold;
                        }

                        > dd {
                            color: $font-color-light;
                            margin-left: 0;
                            font-size: 15px;
                        }
                    }
                }
            }

            > div.head {
                background-color: $color-brand;
            }
        }
    }

    .vacancies-listing__facts .head a::after {
        font-family: "entypo";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: none;
        width: 1em;
        text-align: right;
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #ffffff;
        content: "\e804";
        padding: 3px;
        margin-left: 56px;
    }

    .content-main {
        margin: 0 0 0 -20px;
    }
}

.menu-side {
    @extend %show-desktop-only;
    margin-bottom: 50px;
    margin-top: 80px;

    & > ul > li {
        &:first-child {
            border-width: 1px 0;
        }

        ul {
            padding-left: 10px;
        }
    }

    a {
        @include transition(border-color 200ms ease);
        border-bottom: 1px solid transparent;
        font-family: $font-family-headline;
        font-size: 20px;

        &.active {
            color: $font-color-main;
            font-weight: bold;
        }

        &:hover {
            border-color: $font-color-main;
            color: $font-color-main;
        }
    }

    li {
        border: 1px solid $color-border;
        border-width: 0 0 1px;
        padding: 9px 0 8px;

        &.open {
            border-bottom: 1px solid $color-brand;
        }
    }

    .submenu {
        border-top: 1px solid $color-border;
        padding-top: 5px;

        a {
            color: $font-color-secondary;
            font-family: $font-family-main;
            font-size: 15px;
            font-weight: normal;

            &:hover {
                color: $font-color-secondary;
            }

            &.current {
                background-color: #FFFFFF;
                position: relative;

                &:before {
                    @extend %font-awesome;
                    @include transform(translateY(-50%));
                    color: $color-brand;
                    content: $FA-angle-right;
                    font-size: 16px;
                    right: 2px;
                    top: 50%;
                }
            }
        }

        li {
            border: none;
            padding: 4px 0;

            &.active {
                a {
                    display: block;
                    padding-right: 18px;

                    &:hover {
                        border-color: transparent;
                    }
                }
            }
        }
    }

    ul {
        @extend %list-reset;
    }
}
.column-3--menu-side {
    @media(max-width: 939px){
        display: none !important;
    }
}
﻿.fa-subscription {
  .not-activate & {
    float: revert;
    padding: 0;
    margin: 10px 0;
    width: 100%;
    display: inline-block;
    min-height: 1px;

    @media (min-width: $breakpoint) {
      padding-right: $grid-gutter;
      margin: 0;
      padding-left: $grid-gutter;
      width: calc(100% / 12 * 4);
    }
  }
  .fa-page & {
    @media (min-width: $breakpoint) {
      padding-top: calc(2em + 20px);
    }
  }

  &__modal {
    @include transition(transform 300ms ease);
    height: 100vh;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    h3 {
      margin-bottom: 1.5em;
    }
    &.slide-down-enter,
    &.slide-down-leave-to {
      @include transform(translateY(-100%));
      @include transform(translate3d(0, -100%, 0));
      .fa-subscription__content {
        opacity: 0;
      }
    }
    &.slide-down-enter-to,
    &.slide-down__leave {
      @include transform(translateY(0));
      @include transform(translate3d(0, 0, 0));
      .fa-subscription__content {
        opacity: 1;
      }
    }
    &.slide-down-enter-active {
      transition-delay: 0s;
      .fa-subscription__content {
        transition-delay: 0.3s;
      }
    }
    &.slide-down-leave-active {
      transition-delay: 0.3s;
      .fa-subscription__content {
        transition-delay: 0s;
      }
    }
  }

  &__close {
    @extend %button-reset;
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: #e8e5e0;
    cursor: pointer;
    padding: 5px;
  }

  &__wrapper {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding: 5em 0;
    min-height: 100vh;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__content {
    @include transition(opacity 300ms ease);
    background-color: white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    margin: 0 auto;
    max-width: 90vw;
    min-height: 100px;
    padding: 40px 42px 65px;
    position: relative;
    width: 650px;
  }

  &__form-field {
    position: relative;
    width: 100%;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    label {
      font-size: 0.8em;
      line-height: 13px;
      color: $font-color-light;
      display: inline-block;
      margin-bottom: 10px;
      text-transform: uppercase;
      text-align: left;
    }
  }

  &__help {
    font-size: 0.8em;
    color: red;
    margin-top: 4px;
    font-style: italic;
  }

  &__form-control {
    position: relative;
    display: flex;

    &:last-child {
      margin-bottom: 19px;
    }

    input {
      @extend %reset-appearance;
      height: 40px;
      width: 100%;
      border: $font-color-light 1px solid;
      outline: none;
      padding: 0 15px;
      font-size: 1em;

      &:focus,
      &:hover {
        border: $color-jobbrand 1px solid;
      }
    }
  }

  &__box {
    display: none;
    h2 {
      padding-top: 0;
    }
    @media (min-width: $breakpoint) {
      display: block;
    }

    &--mobile {
      display: block;
      .black {
        margin-top: 0;
      }
      @media (min-width: $breakpoint) {
        display: none;
      }
    }
  }

  & + .bodyfield {
    margin: 10px 0;
    width: 100%;
    float: left;
    display: inline-block;
    min-height: 1px;

    @media (min-width: $breakpoint) {
      padding-right: $grid-gutter;
      margin: 0;
      width: calc(100% / 12 * 8);
    }
  }

  &__error {
    color: red;
  }

  &__button {
    background-color: #36864a;
    border: none;
    color: #ffffff;
    display: block;
    font-family: $font-family-headline;
    margin-top: 35px;
    padding: 15px 35px 15px 15px;
    position: relative;
    text-align: left;
    width: 100%;
    font-size: 19px;

    &.black {
      padding: 12px 30px 9px 50px;
      transition: background-color 0.3s ease;
      &:before {
        background-color: rgb(0, 0, 0);
        bottom: 0px;
        content: "\f105";
        font-size: 27px;
        left: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 13px;
        font-family: "FontAwesome";
        font-weight: normal;
        position: absolute;
      }
      &:hover {
        background-color: #317943;
        color: white;
      }
    }
    &.fa-subscription__button--loading {
      color: #36864a;
      > span {
        display: none;
      }
      &:before {
        content: none;
      }
    }
    &.link {
      background-color: transparent;
      color: black;
      text-decoration: underline;
      padding: 0;
      font-family: $font-family-main;
      transition: text-decoration ease 0.3s;
      font-size: 15px;
      width: auto;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    > span {
      font-size: 18px;
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      padding: 0 5px;
    }
  }
  &__loader {
    display: block;
    font-size: 0px;
    text-align: center;
    padding: 0px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    span {
      vertical-align: middle;
      border-radius: 100%;
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 3px 2px;
      -webkit-animation: loader 0.8s linear infinite alternate;
      animation: loader 0.8s linear infinite alternate;

      &:nth-child(1) {
        animation-delay: -1s;
        background: rgba(255, 255, 255, 0.6);
      }

      &:nth-child(2) {
        animation-delay: -0.8s;
        background: rgba(255, 255, 255, 0.8);
      }

      &:nth-child(3) {
        animation-delay: -0.26666s;
        background: rgba(255, 255, 255, 1);
      }

      &:nth-child(4) {
        animation-delay: -0.8s;
        background: rgba(255, 255, 255, 0.8);
      }

      &:nth-child(5) {
        animation-delay: -1s;
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }

  @keyframes loader {
    from {
      transform: scale(0, 0);
    }

    to {
      transform: scale(1, 1);
    }
  }

  @-webkit-keyframes loader {
    from {
      -webkit-transform: scale(0, 0);
    }

    to {
      -webkit-transform: scale(1, 1);
    }
  }
  &__checkbox {
    margin-bottom: 0.5em;

    label {
      padding-left: 25px;
      cursor: pointer;
      position: relative;
      transition: all 200ms ease-in;
      font-size: 17px;
      line-height: 19px;
      display: inline-block;
      color: $font-color-light;
      text-transform: uppercase;

      &:before {
        display: inline-block;
        content: "";
        width: 17px;
        height: 17px;
        background: white;
        border: 2px solid $font-color-light;
        position: absolute;
        top: calc(8px * 1.15);
        left: 0;
        transform: translateY(-50%);
        transition: all 200ms ease-in;
      }

      &::after {
        content: "";
        width: 8px;
        height: 16px;
        display: block;
        position: absolute;
        left: 6px;
        top: 0;
        transform: rotate(45deg);
        visibility: hidden;
        border-bottom: transparent 3px solid;
        border-right: transparent 3px solid;
        transition: all 200ms ease-in;
      }

      &.checked {
        &:after {
          border-color: $color-brand;
          visibility: visible;
        }
      }
    }

    input {
      cursor: pointer;
      position: absolute;
      opacity: 0;

      &:focus,
      &:hover {
        & + label {
          &::before {
            border-color: $color-brand;
          }
        }
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  &__list {
    margin-bottom: 2em;
  }
}

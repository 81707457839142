﻿footer {
    background-color: $color-footer-background;
    border-top: 25px solid #FFFFFF;
    color: #FFFFFF;
    font-size: 15px;
    padding: 0 20px 50px;

    @media (min-width: $breakpoint) {
        padding: 0;
    }

    a {
        color: #FFFFFF;
    }

    .footer__logos {
        margin-left: -2rem;
        margin-right: -2rem;
        display: flex;
        justify-content: center;
        padding: 50px 0;
        li {
            margin: 0 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            a{
                display: block;
            }
        }
    }

    .headline,
    h2 {
        font-size: 22px;
        margin: 28px 0 15px;
        font-family: "oswaldlight",Arial,sans-serif;
    }

    .byvaaben-container {
        overflow: hidden;

        @media (min-width: $breakpoint) {
            display: block;
        }

        .byvaaben {
            display: none;
        }

        .byvaaben,
        ul {
            @media (min-width: $breakpoint) {
                display: inline-block;
                float: right;
            }
        }

        li {
            margin-bottom: 15px;
        }

        ul {
            font-size: 14px;
            margin: 0 30px 0 0;

            @media (min-width: $breakpoint) {
                margin-top: 34px;
                /*text-align: right;*/
            }

            a {
                @include transition(border-color 200ms ease);
                border-bottom: 1px solid transparent;

                &:hover {
                    border-color: #FFFFFF;
                    color: inherit;
                }
            }
        }
    }

    .logo {
        margin: 40px 0 10px $grid-gutter;

        @media (min-width: $breakpoint) {
            display: none;
        }
    }

    .margin-top {
        margin-top: 20px;
    }

    ul {
        @extend %list-reset;

        &.social {
            margin: 25px 0;
            overflow: hidden;

            a {
                @include transition(color 200ms ease);
                border: none;
                color: #FFFFFF !important;
                font-size: $font-size-base*1.5;
            }

            li {
                display: inline-block;
                float: left;
                margin-right: 15px;
            }
        }
    }

    .underline {
        text-decoration: underline;
    }
}

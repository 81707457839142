﻿#cludo-search-form {
    button {
        color: $color-brand !important;
    }

    input {
        border-color: $color-brand !important;
    }
}
#cludo-404 {
    .powered-by {
        display:none;
    }
}
﻿.search-container {

	.search-input {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
		margin-bottom: 20px;
		position: relative;

		i {
			font-size: 24px;
			position: absolute;
			right: 8px;
			top: 7px;
		}
		
		input {
			border: none;
			font-family: $font-family-headline;
			font-size: 20px;
			padding: 13px 35px 9px 15px;
			width: 100%;
		}
	}

	.search-results {
		@extend %list-reset;

        padding-left: 0 !important;

		.document {
			
			.date {
				color: $font-color-light;
				float: right;
			}
			.type {
				background-color: #FFFFFF;
				color: $font-color-main;
				font-size: 14px;
				margin-right: 5px;
				padding: 1px 9px 4px;
			}
		}

		.document,
		.news {
			margin-bottom: 15px;

			a {
				@include transition(border-color 200ms ease);

				border-bottom: 1px solid transparent;
				color: $font-color-main;

				&:hover {
					border-color: $font-color-main;
				}
			}
		}

		em {
			background-color: #FFF179;
		}

		h2 {
			font-family: $font-family-main;
			font-size: $font-size-base;
			font-weight: normal;
			margin-top: 0;
			overflow: hidden;
		}

		.link {
			@include transition(background-color 200ms ease);

			background-color: #36864A;
			bottom: 0;
			color: #FFFFFF;
			display: inline-block;
			font-family: $font-family-headline;
			font-size: 19px;
			padding: 11px 40px 9px 20px;
			position: absolute;
			right: 0;

			&:hover {
				background-color: #187E3D;
			}

			i {
				font-size: 24px;
				position: absolute;
				right: 6px;
				top: 8px;
			}
		}

		.news {
			.date {
				background-color: #FFFFFF;
				color: $font-color-main;
				font-size: 14px;
				margin-right: 5px;
				padding: 1px 9px 4px;
			}
		}

		.site {
			margin-bottom: 25px;
			padding-top: 10px;

			a {
				@include transition(border-color 200ms ease);

				border-bottom: 1px solid transparent;
				color: $font-color-main;

				&:hover {
					border-color: $font-color-main;
				}
			}

			.breadcrumb {
				@extend %list-reset;
	
				overflow: hidden;
			}

			.cta {
				background-color: #36864A;
				color: #FFFFFF;
				font-size: 15px;
				font-weight: normal;
				margin-right: 5px;
				padding: 1px 7px 7px;
			}

			.date {
				color: $font-color-light;
				float: right;
				font-weight: normal;
			}

			h2 {
				font-weight: bold;
			}
			
			p {
				margin-bottom: 0;
			}
		}

		.site-cta {
			background-color: #FFFFFF;
			margin-bottom: 15px;
			padding: 10px 10px 40px;
			position: relative;

			@media (min-width: $breakpoint) {
				padding-bottom: 20px;
			}

			h2 {
				font-family: $font-family-headline;
				font-size: 22px;
			}

			p {
				@media (min-width: $breakpoint) {
					width: 65%;
				}
			}
		}
	}
}


.search-tabs {
	margin-bottom: 20px;
	margin-top: 46px;

	a {
		background-color: #FFFFFF;
		display: block;
		font-family: $font-family-headline;
		font-size: 25px;
		padding: 9px 10px 7px 5px; 
	}

	.content {
		@extend %show-desktop-only;
	}

	li,
	li div {
		@include transition(border-color 200ms ease);

		border-bottom: 1px solid $color-border;

		&.active,
		&:hover {
			border-color: $color-brand;

			a,
			.number {
				color: $color-brand;
			}
		}

		&:last-child {
			border-bottom: none;
		}
	}

	.number {
		@include transition(color 200ms ease);

		color: $font-color-light;
		float: right;
	}

	p {
		color: $font-color-light;
		font-size: 14px;
	}

	.toggle-content {
		@extend %show-mobile-only;

		position: relative;

		&.active {
			i:before {
				content: '\e803';
			}
		}

		i {
            @include transform(translateY(-50%));

			color: $font-color-main;
			position: absolute;
			right: 10px;
			top: 50%;
			z-index: 97;
		}

		.toggle {
			display: block;
            padding-right: 25px;
			position: relative;
			width: 100%;
			z-index: 98;
		}
	}

	ul {
		@extend %list-reset;
	}
}


.autocomplete-suggestion {
	@include transition(background-color 200ms ease);

	background-color: #FFFFFF;
    cursor: pointer;
	padding: 3px 15px;

	&.autocomplete-selected {
		background-color: shade(#FFFFFF, 5%);
	}
}


.autocomplete-suggestions {
	background-color: #FFFFFF;
	box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
	max-height: 300px;
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	padding: 10px 0;
	width: 100% !important;
}


#lblMenuSearch,
#lblSearch {
    display: none;
}


.googlesearch {
    .article {
        .content {
            overflow: visible;
        }
    }
}
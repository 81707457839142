﻿.mobile-menu-container {
    top: $header-mobile-height;
    display: none;
    position: fixed;
    height: calc(100vh - #{$header-mobile-height});
    background: $content-background;
    z-index: 999;
    width: 100vw;
    padding-bottom: 50px;
}

.show-menu {
    .mobile-menu-container {
        overflow-y: auto;
        display: block;
    }
}

﻿.pagination {
	@extend %list-reset;
	
	margin-top: 30px;
	overflow: hidden;

	a {
		@include transition(background-color 200ms ease);

		background-color: #FFFFFF;
		display: block;
		font-size: 14px;
		padding: 5px 10px;

		&.active {
			background-color: $color-brand;
			color: #FFFFFF;

			&:hover {
				color: #FFFFFF;

				.link {
					border-color: transparent;
				}
			}
		}

		&:hover {
			color: $font-color-main;
		}
	}

	li {
		display: inline-block;
		float: left;
		margin-right: 5px;
	}

	.link {
		@include transition(border-color 200ms ease);

		border-bottom: 1px solid transparent;

		&:hover {
			border-color: $font-color-main;
		}
	}
}
﻿
.news-overview {
    padding-bottom: 20px;


    a {
        display: block;
        margin-bottom: 25px;

        @media (min-width: $breakpoint) {
            margin-bottom: 12px;
            overflow: hidden;
        }
    }

    .date {
        background-color: #FFFFFF;
        color: $font-color-main;
        font-size: 14px;
        margin-right: 5px;
        padding: 4px 9px;
    }

    .month {
        background-color: #FFDF00;
        display: inline-block;
        font-size: 23px;
        margin: 20px 0 13px;
        padding: 8px 8px 5px;
    }

    ol {
        @extend %list-reset;
    }

    span {

        @media (min-width: $breakpoint) {
            display: inline-block;
            float: left;
        }

        &.date {
            margin-bottom: 5px;

            @media (min-width: $breakpoint) {
                margin-bottom: 0;
            }
        }

        &.title {
            display: block;
            margin-top: 10px;
            margin-left: 1px;

            @media (min-width: $breakpoint) {
                display: inline-block;
                margin-top: 3px;
                margin-left: 0px;
                width: calc(100% - 100px);
                padding-top: 0px;
            }
        }
    }
}

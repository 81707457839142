﻿$yellow-color: #FFDF00;
$yellow-color-hover: #E6C900;

.news-frontpage {
	margin-top: 50px;
	padding-bottom: 20px;

	.element {
		@include transition(border-color 200ms ease);

		border-left: 10px solid $yellow-color;
		display: inline-block;
		float: left;
		margin-bottom: 20px;
		padding-left: 10px;
		width: 100%;

		&:last-child {
			margin-bottom: 10px;
		}

		@media (min-width: $breakpoint) {

			&,
			&:last-child {
				margin-bottom: 40px;
			}

			width: 50%;
		}

		a {
			color: $font-color-main;
			margin-right: 10px;
		}

		a,
		h2 {
			display: inline-block;
			margin: 0;
		}

		.date {
			color: #767676;
			font-family: $font-family-headline;
			font-size: 18px;

			@media (min-width: $breakpoint) {
				font-size: 22px;
			}
		}

		h2 {
			font-size: 18px;

			@media (min-width: $breakpoint) {
				font-size: 22px;
			}
		}

		.text {
			padding-right: 10px;
		}
	}

	.link {
		@include transition(background-color 200ms ease);

		background-color: $yellow-color;
		color: $font-color-main;
		display: block;
		font-family: $font-family-headline;
		font-size: 22px;
		padding: 13px 10px 12px 10px;

		&:first-child {
			margin-bottom: 10px;
		}

		&:hover {
			background-color: $yellow-color-hover;
		}

		span {
			margin-right: 5px;
		}
	}
	.section-headline {
		margin-bottom: 20px;
		margin-top: 20px;
	}
}

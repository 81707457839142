﻿.signlanguage {
    $sliderDimension: 25px;
    margin-right: 20px;

    &__content {
        display: flex;
        align-items: center;
    }

    &__title {
        margin: 0 10px 0 0;
    }

    &__switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: $sliderDimension;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .signlanguage__slider {
            background-color: $color-signlanguage-filled;
        }

        &:focus + .signlanguage__slider {
            box-shadow: 0 0 1px $color-signlanguage;
        }

        &:checked + .signlanguage__slider:before {
            transform: translateX($sliderDimension);
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /*background-color: #ccc;*/
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
        border: 2px solid $color-signlanguage;

        &:before {
            position: absolute;
            content: "";
            height: $sliderDimension;
            width: $sliderDimension;
            left: -2px;
            top: -2px;
            background-color: white;
            transition: .4s;
            border-radius: 50%;
        }
    }
}

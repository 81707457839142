﻿.form-wrapper {
    * {
        box-sizing: border-box;
    }

    form {
        * {
            outline: none;
        }

        input, textarea, select {
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857143;
            color: #555;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 4px;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

            &:focus {
                border-color: #843534;
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
            }

            &[type=submit] {
                display: inline-block;
                padding: 6px 12px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                touch-action: manipulation;
                cursor: pointer;
                user-select: none;
                border: 1px solid transparent;
                color: #333;
                border-color: #ccc;
                width: auto;
                box-shadow: none;
                margin-top: 20px;
            }
        }

        select[data-sc-field-name="List Box"] {
            min-height: 75px;
        }

        textarea {
            min-height: 100px;
        }

        label {
            display: inline-block;
            max-width: 100%;
            display: flex;
            align-items: center;
            font-weight: 700;
            cursor: pointer;

            &[for],
            &[class] {
                margin-top: 15px;
                margin-bottom: 5px;
            }

            &[for] {
                cursor: initial;
            }

            input {
                width: auto;
                display: inline-block;
                margin: 0 5px 0;
                box-shadow: none;
                font-weight: normal;
                cursor: pointer;
                background-color: transparent;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .field-validation-error {
            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: normal;
            display: block;
            margin-top: 5px;
            margin-bottom: 10px;
            color: #a94442;
        }
    }
}

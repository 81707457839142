﻿//base
$grid-columns: 12;
$grid-gutter: 12px;
$max-width: 940px;
$max-width2: 750px;
$menu-mobile-width: 100vw;
$mobile-search-width: 90vw;
$mobile-search-transform: 100vw;
$slider-height: 350px;
$slider-dot-size: 11px;
$spot-image-height: 170px;
$spot-image-height-small: 120px;

//breakpoints
$breakpoint: $max-width;
$breakpoint2: $max-width2;

//mixin
@mixin max-res1 {
    @media (max-width: #{$breakpoint}) {
        @content;
    }
}

@mixin max-res2 {
    @media (max-width: #{$breakpoint2}) {
        @content;
    }
}

@mixin aaa {
    &:hover,
    &:active,
    &:focus {
        @content;
    }
}

//colors
$color-brand: #009ce2;
$color-jobbrand: #009ce2;
$color-border: #dddddd; //check in photoshop
$color-footer-background: #062d62;
$color-slider: #ffffff;
$color-slider-background: #000000;
$color-FA-angle-right: #072a63;
$color-topnews-textoverlay: rgba(7, 42, 99, 0.8);
$color-spot-textoverlay: rgba(0, 0, 0, 0.65);
$color-selvbetjening-background: #36864a;
$color-selvbetjening-background-hover: shade(#36864a, 10%);
$color-selvbetjening-border: tint(#36864a, 30%);
$color-selvbetjening-text: #ffffff;
$color-cookie-banner: rgba(0, 156, 226, 1);
$color-signlanguage: $color-brand;
$color-signlanguage-filled: $color-brand;
$color-anchor-hover: #0073a6;

//content
$content-background: #f0ede8;
$content-maxwidth: 1000px;
$header-mobile-height: 80px;

//font
$font-color-light: #6c6c6a;
$font-color-main: #000000;
$font-color-secondary: #333333;
$font-family-main: Arial, sans-serif;
$font-family-headline: "oswaldlight", Arial, sans-serif;
$font-line-height-base: 1.35;
$font-size-base: 15px;
//tables
$color-table-head: #072a63;
$color-table-head--secondary: #1a6cef;
$color-table-text-light: #fff;
$color-table-text-dark: $font-color-main;
//font-awesome icons
$FA-angle-left: "\f104";
$FA-angle-right: "\f105";
$FA-minus: "\f068";

﻿.slider {
    @extend %show-desktop-only;
    @include user-select(none);
    position: relative;
    touch-action: pan-y;
    
    header & {
        max-height: $slider-height;
        overflow: hidden;
    }

    button {
        @include appearance(none);
        background-color: transparent;
        border: none;
        &:focus {
            outline: none;
        }
    }

    button:focus {
        border: solid 1px #A6C7FF;
    }

    .container {
        height: 100%;
    }

    .content {
        color: $color-slider;
        height: auto;
        margin-top: $slider-height*0.4;
        padding-bottom: 50px;
        padding-left: 50px;
        position: relative;
        width: 55%;

        @media (min-width: 1024px) {
            padding-left: 0;
        }

        .arrow {
            @extend %font-awesome;
            background-color: $color-brand;
            bottom: 0;
            color: $color-slider;
            display: block;
            font-size: 40px;
            padding: $font-size-base*0.25 $font-size-base $font-size-base*0.25 $font-size-base*1.15;
            position: absolute;
            right: 0;
        }

        h1 {
            font-size: 33px;
            margin-bottom: 7px;
        }

        p {
            font-family: $font-family-headline;
            font-size: 20px;
            line-height: 1.1;
        }
    }

    &.negative {
        .content {
            background-color: rgba(0, 0, 0, 0.75);
            color: $color-slider;
            margin-top: $slider-height*0.35;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 15px;

            h1 {
                margin-top: 0;
            }
        }

        .slick-dots {
            top: $slider-height*0.45;
        }

        .slick-next,
        .slick-prev {
            background-color: rgba(0, 0, 0, 0.75);
            padding: 0 25px;
        }

        .slick-next {
            &:before {
                right: 0;
            }
        }

        .slick-prev {
            &:before {
                left: 0;
            }
        }
    }
}


.slick-dots {
	@extend %list-reset;

	left: 46.5%;
	position: absolute;
	top: $slider-height*0.4;

	button {
		color: transparent;
	}

	li {
		background-color: $color-slider;
		border-radius: 50%;
		display: inline-block;
		float: left;
		height: $slider-dot-size;
		margin-right: $font-size-base*0.75;
		width: $slider-dot-size;

		&.slick-active {
			background-color: $color-brand;
		}
	}
}


.slick-list {
    margin: 0;
	overflow: hidden;
    padding: 0;
	position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
    }
}


.slick-next {
	right: 0%;

	&:before {
		content: $FA-angle-right;
		left: $font-size-base*0.15;
	}
}


.slick-next,
.slick-prev {
	color: transparent;
	height: $font-size-base*5;
	margin-top: - $font-size-base*1.25;
	position: absolute;
	top: 50%;
	width: $font-size-base*2;

	&:before {
		@extend %font-awesome;

		color: $color-slider;
		font-size: $font-size-base*3;
		top: $font-size-base*0.8;
	}
}


.slick-prev {
	left: 0%;

	&:before {
		content: $FA-angle-left;
		right: $font-size-base*0.15;
	}
}


.slick-slide {
	color: $color-slider;
	display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
		border: 1px solid transparent;
        display: block;
        height: auto;
    }
}


.slick-slider .slick-track,
.slick-slider .slick-list {
	@include transform(translate3d(0,0,0));
    
}


.slick-track {
	background-color: $color-slider-background;
	height: $slider-height;
    left: 0;
	position: relative;
    top: 0;
    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }

	.single-slide-img {
		float: left;
		width: 100%;
		height: 100%;
	}
    .img-16_9 {
        padding-bottom: 0;

        img { 
            display: block !important;
        }
    }
}

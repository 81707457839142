﻿.top-news {
  margin-top: 20px;
  position: relative;

  // for keeping 16:9 resolution
  .sixteen-nine {
    opacity: 0;
  }

  @media (min-width: $breakpoint) {
    height: 338px;
    margin-top: 0;

    &:hover {
      .overlay {
        /*background-color: rgba(7, 42, 99, 0.8);*/
        background-color: $color-topnews-textoverlay;
      }
      .text-overlay {
        background-color: transparent;
      }
    }
    &.wide {
      height: 529px;
    }
    + .top-news {
      margin-top: 2rem;
    }
  }

  .cover-image {
    max-height: 100%;
    img {
      width: 100%;
      opacity: 0;
    }
  }

  h1 {
    color: $font-color-main;
    font-size: 25px;
    padding: 10px 0 10px 20px;
    width: 100%;

    @media (min-width: $breakpoint) {
      color: #ffffff;
      font-size: 33px;
    }

    span {
      @media (min-width: $breakpoint) {
        display: block;
        width: calc(100% - 80px);
      }
    }
  }

  h1,
  span {
    display: table-cell;
    vertical-align: middle;
  }

  span > span {
    color: $font-color-main;
    font-size: 32px;

    @media (min-width: $breakpoint) {
      /*background-color: #072A63;*/
      background-color: $color-FA-angle-right;
      bottom: 0;
      color: #ffffff;
      position: absolute;
      right: 0;
      top: 0;
      width: 70px;
    }

    &:before {
      float: right;

      @media (min-width: $breakpoint) {
        @include transform(translateX(-50%) translateY(-50%));

        float: none;
        left: 50%;
        position: absolute;
        top: 50%;
      }
    }
  }

  .overlay {
    display: none;

    @media (min-width: $breakpoint) {
      @include transition(background-color 200ms ease);

      background-color: transparent;
      bottom: auto;
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .text-overlay {
    @include transition(background-color 200ms ease);

    background-color: rgba(255, 255, 255, 0.75);
    bottom: 0;
    display: table;
    position: absolute;
    width: 100%;

    @media (min-width: $breakpoint) {
      /*background-color: rgba(7, 42, 99, 0.8);*/
      background-color: $color-topnews-textoverlay;
    }
  }
}

﻿.spot {
	display: block;

	&:hover {
		h2 {
			border-color: $font-color-main;
		}
		.overlay {
			/*background-color: rgba(0, 0, 0, 0.65);*/
			background-color: $color-spot-textoverlay;
		}
	}

	& > div {
		margin-bottom: 5px;
		position: relative;

		&:last-child {
			max-width: 95%;
		}
	}

	.cover-image {
		max-height: 100%;
		img {
			width: 100%;
			opacity: 0;
		}
	}

	h2 {
		@include transition(border-color 200ms ease);

		border-bottom: 1px solid transparent;
		color: $font-color-main;
		display: inline;
		font-size: 18px;
		margin: 0;

		@media (min-width: $breakpoint) {
			font-size: 22px;
		}
	}

	.entypo-right-open-big {
		background-color: $font-color-main;
		bottom: 0;
		color: #FFFFFF;
		font-size: 30px;
		padding: 10px 9px 10px 14px;
		position: absolute;
		right: 0;
	}
	.entypo-right-open-big  {
			background-color: $color-FA-angle-right;
    }

	.overlay {
		@include transition(background-color 400ms ease);

		background-color: rgba(0, 0, 0, 0.00);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&-top {
		//SpotTopNews & SpotTopImage
		@media (max-width: $breakpoint) {
			display: block;
			position: relative;
			width: 100%;
		}
	}
}

﻿.selvbetjening {
    background-color: $color-selvbetjening-background;
    overflow: hidden;
    z-index: 1;
    position: relative;
    transition: opacity 300ms ease;

    &:last-child {
        margin-bottom: 20px;
    }


    a {
        @include transition(background-color 200ms ease);
        border-bottom: 1px solid $color-selvbetjening-border;
        color: $color-selvbetjening-text;
        display: block;
        font-family: $font-family-headline;
        font-size: 19px;
        padding: 15px 15px 12px;
        position: relative;

        &.active,
        &:hover {
            background-color: $color-selvbetjening-background-hover;
        }

        &.call-to-action,
        &.OutsideInterval {
            padding: 15px 60px 12px 15px;

            &:after {
                @extend %font-awesome;
                background-color: $font-color-main;
                bottom: 0;
                color: #FFFFFF;
                content: $FA-angle-right;
                font-size: 31px;
                padding: 7px 15px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }

    h1 {
        padding-top: 0;
        border-bottom: none;
        font-size: 30px;
        padding: 10px 0 4px 15px;
        color: $color-selvbetjening-text;

        &,
        a {
            margin: 0;
            text-transform: uppercase;
        }

        a {
            border-bottom: none;
            /*color: $color-selvbetjening-text;*/
            font-size: 30px;
            padding: 0px;

            &:hover {
                background-color: $color-selvbetjening-background !important;
            }
        }
    }

    .info {
        @extend %show-desktop-only;
        background-color: #FFFFFF;
        padding: 20px;

        a {
            background-color: transparent;
            border-bottom: none;
            font-weight: normal;
            padding: 0;
            text-decoration: underline;
            display: inline;

            &:hover {
                color: $color-brand;

                i {
                    color: #676767;
                }
            }

            span {
                margin-right: 5px;
            }
        }

        a,
        h2 {
            font-family: $font-family-main;
            font-size: $font-size-base;
        }

        a,
        p {
            color: #676767;
            margin: 0 0 25px;
        }

        h1 {
            font-size: 22px;
            margin-bottom: 5px;
            padding: 0;
            text-transform: none;
        }

        h1,
        h2 {
            color: $font-color-main;
        }

        h2 {
            font-weight: bold;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        span {
            &.margin-bottom {
                display: block;
                margin-bottom: 10px;
            }
        }

        .time {
            float: right;
            padding-right: 20px;
        }
    }

    .info-header {
        @extend %show-desktop-only;
        background-color: $color-brand;
    }

    .info-toggle {
        @extend %show-mobile-only;
        background-color: shade($content-background, 5%);
        border-bottom: none;
        color: $font-color-main;

        &.active {
            background-color: shade($content-background, 5%);

            i:before {
                content: '\e803';
            }
        }

        &.noselfservice {
            background-color: $color-brand;
            color: #FFFFFF;

            &.active,
            &:hover {
                background-color: shade($color-brand, 15%);
            }

            i {
                color: #FFFFFF;
            }
        }

        &:hover {
            background-color: shade($content-background, 15%);
        }

        i {
            color: $font-color-main;
            position: absolute;
            right: 15px;
            top: 13px;
            z-index: 97;
        }



        .link {
            display: block;
            position: relative;
            z-index: 98;
        }
    }

    li {
        &:first-child {
            a {
                border-top: 1px solid $color-selvbetjening-border;
            }
        }
    }

    .overview {
        position: relative;

        &:hover {
            background-color: $color-selvbetjening-background-hover;
            text-decoration: underline;
        }

        span {
            font-size: 32px;
            position: absolute;
            right: 7px;
            top: 8px;
        }
    }

    .top-shadow {
        background: linear-gradient(to bottom, rgba(201,201,201,0.15) 0%, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0) 17.5%);
        display: block;
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    ul {
        @extend %list-reset;
        margin: 0;
    }

    .sectionfrontpage &, .home & {
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }
}

@media print {
    #modal:not(.active) {
        display: none;
    }
}

﻿.modal {
  @include transform(translateY(-100%));
  @include transform(translate3d(0, -100%, 0));
  @include transition(transform 300ms ease);

  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;

  &.active {
    @include transform(translateY(0));
    @include transform(translate3d(0, 0, 0));
    .canvas & {
    }
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;

    @media (min-width: $breakpoint) {
    }
  }
  .content {
    @include transform(translateX(-50%) translateY(-50%));

    background-color: #ffffff;
    left: 50%;
    padding: 20px 10px 10px;
    position: absolute;
    top: 50%;
    width: 300px;

    @media (min-width: $breakpoint) {
      padding: 30px 20px 10px;
      width: 500px;
    }
  }
  h1,
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 0;
    padding-right: 35px;
  }
  span {
    background-color: #e8e5e0;
    cursor: pointer;
    padding: 5px;
  }
  .link {
    background: $color-selvbetjening-background;
    color: $color-selvbetjening-text;
    display: inline-block;
    float: right;
    font-family: $font-family-headline;
    font-size: 19px;
    margin-bottom: 5px;
    padding: 12px 30px 9px 50px;
    position: relative;
    text-decoration: none;

    &:before {
      @extend %font-awesome;
      @include transform(translateY(-50%));
      background-color: $font-color-main;
      content: $FA-angle-right;
      font-size: 27px;
      padding: 0px 12px 0px 16px;
      position: absolute;
      left: 0;
      top: 50%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &:hover {
      background-color: $color-selvbetjening-background-hover;
    }
  }
}

.overlay,
.dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  position: absolute;
  width: 100%;
}

.test {
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

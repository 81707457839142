﻿.menu-mobile {
    @include transition(transform 400ms ease);
    -webkit-overflow-scrolling: touch;
    z-index: 98;

    & > ul {
        width: 100%;

        & > li {
            font-family: $font-family-headline;
            font-size: 22px;

            .menu-icon {
                padding: 11px 15px;
            }

            &.active > .submenu.active {
                border-color: $color-brand;
            }

            & > .submenu-toggle {
                border-bottom: 1px solid $color-border;
            }

            & > .submenu-toggle.active {
                & + .submenu.active {
                    border-color: $color-brand;
                }
            }

            &:first-child {
                & > .submenu-toggle {
                    padding-top: 15px;
                }
            }

            &:not(:last-child) {
                .submenu {
                    &.active {
                        border-color: transparent;
                    }
                }
            }

            & > .submenu {
                &.active {
                    border-color: $color-border;
                }
            }

            & > ul > li {
                font-size: 15px;

                .menu-icon {
                    padding: 7px 15px;
                }

                ul {
                    padding-left: 10px;
                }
            }
        }
    }

    li {
        font-family: $font-family-main;
        font-weight: normal;
    }

    .menu-mobile-service {
        margin-top: 15px;

        a {
            color: #082561;
            display: block;
            padding: 3px 0;

            &:focus {
                border: 1px solid #000;
            }
        }

        li {
            font-family: $font-family-main;
            font-size: 14px;
            font-weight: normal;
        }
    }

    .submenu {
        @include transition(border-color 200ms ease);
        border-bottom: 1px solid transparent;
        display: none;

        a {
            display: block;
            padding: 8px 63px 8px 15px;
            position: relative;

            &:focus {
                border: 1px solid #000;

                @media (hover: none) {
                    border: none;
                    outline: none;
                }
            }
        }
    }

    .submenu-toggle {
        display: block;
        overflow: hidden;
        padding: 12px 10px 6px 15px;
        position: relative;

        &.active {
            .menu-icon:before {
                content: '\e803';
            }
        }

        .menu-icon {
            color: $font-color-main;
        }
    }

    ul {
        @extend %list-reset;

        .menu-icon {
            border: none;
            border-left: 1px solid $color-border;
            background: transparent;
            color: transparent;
            font-size: 22px;
            position: absolute;
            right: 0;
            top: 0;

            &:focus {
                outline: none;
                border: 1px solid #000;
            }
        }

        ul {
            .submenu-toggle {
                &.active {
                    span:not(.menu-icon) {
                        background-color: #FFFFFF;
                        border-radius: 3px;
                        display: block;
                        padding: 3px 0;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

﻿.menu {
  background: $color-brand;
  z-index: 200;
  position: relative;
  &__container {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include max-res1 {
      height: $header-mobile-height;
    }
  }

  &__button {

    &-container {
      margin-left: auto;
      display: none;
      
      @include max-res1 {
        display: block;

      }
    }
    @include max-res1{
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &[aria-expanded="true"] {
      .mobile-menu-open,
      .fa-bars {
        display: none;
      }
      .mobile-menu-close,
      .fa-close {
        display: block;
      }
    }
    &[aria-expanded="false"] {
      .mobile-menu-open,
      .fa-bars {
        display: block;
      }
      .mobile-menu-close,
      .fa-close {
        display: none;
      }
    }

    background-color: transparent;
    padding: 0 20px;
    border: none;
    color: #fff;
    font-family: $font-family-headline;
    font-size: 18.7px;
    font-weight: bold;
    height: 100%;
    color: white;



    span.fa {
      color: #fff;
      font-size: 20px;
      padding-right: 5px;
      margin-top: -2px;
      @include max-res1{
        padding: 0;
        margin-bottom: 8px;
        margin-top: 0;
      }
    }
    @include aaa {
      outline: none;
      background-color: darken($color: $color-brand, $amount: 7%);
    }
  }

  &__logo {
    &-container {
      @include max-res1 {
        margin-left: 20px;
        // height: 60px;
     }
     height: $header-mobile-height;
      padding: 15px 0;
      display: block;
    }
    height: 100%;
    display: block;
    width: auto;
  }

  &__items {
    @extend %list-reset;
    @include max-res1 {
      display: none;
    }
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin: 0 -11px;
    padding: 0 11px;
  }

  &__item {
    padding: 0 11px;
  }

  &__link {
    @include transition(border-color 200ms ease);
    border-bottom: 1px solid transparent;
    color: white;
    font-family: $font-family-headline;
    font-size: 18.7px;
    font-weight: bold;
    @include aaa {
      border-color: #fff;
      color: #fff;
    }
  }
  &__modal {
    @include transform(translateY(-100%));
    @include transition(opacity 300ms ease);
    opacity: 0;
      height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99;

    &.active {
      @include transform(translateY(0));
      opacity: 1;
    }
    .overlay {
      background-color: rgba(0, 0, 0, 0.7);
      height: 100%;
      position: absolute;
      width: 100%;
    }
    &--mobile {
      z-index: 201;
      .overlay {
        background-color: transparent;
      }
    }
  }
  .test {
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

﻿.breadcrumb {

    @media (min-width: $breakpoint) {
        margin: 5px 0 $grid-gutter*2;
    }

    a {
        @include transition(border-color 200ms ease);

        border-bottom: 1px solid transparent;
        color: #666666;
        font-size: 12px;

        &:hover {
            border-color: #666666;
        }
    }

    li {
        display: inline-block;
        margin: 0 12px 6px 0;

        @media (min-width: $breakpoint) {
            float: left;
        }

        &:not(:last-child) {
            padding-right: 18px;
            position: relative;

            &:after {
                @extend %font-awesome;

                color: #666666;
                content: $FA-angle-right;
                font-size: 8px;
                right: 0;
                top: 6px;
            }
        }
    }

    ol {
        @extend %list-reset;

        overflow: hidden;
        padding: 12px 12px 0 12px;

        @media (min-width: $breakpoint) {
            padding: 0;
        }
    }
}

﻿
.ubivox, .agendasandminutes {

    .button {
        background-color: #36864A;
        border: none;
        color: #FFFFFF;
        display: block;
        font-family: $font-family-headline;
        margin-top: 35px;
        padding: 10px 15px 7px;
        position: relative;
        text-align: left;
        width: 100%;

        &.black {
            background-color: #000000;
            color:#fff;
        }

        &:focus {
            outline: none;
        }

        span {            
            font-size: 25px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .checkbox-container {
        background-color: #FFFFFF;
        display: block;
        margin-bottom: 10px;
        padding: 7px;
        
        & + .button {
            margin-top: 0;
        }

        input,
        label {
            display: inline-block;
            width: auto;
        }
    }

    .headline {
        margin: 0 0 20px;
    }

    input {
        &:not([type="checkbox"]),
        &:not([type="radio"]) {
            border: 1px solid transparent;
            padding: 5px;
            transition: background-color 300ms ease, border-color 300ms ease;
            
            &:focus {
                border-color: $color-border;
            }

            &.error {
                background-color: #FFBFBF;
            }
        }
    }

    input,
    label {
        display: block;
        width: 100%;
    }

    label {
        color: #6c6c6c;
        cursor: pointer;
        font-family: $font-family-headline;
        font-size: 13px;
        margin-bottom: 2px;
        padding-top: 5px;
        text-transform: uppercase;

        &.error {
            display: none !important;
        }
    }

    .row {
        margin-bottom: 15px;
        overflow: hidden;

        .input-container {
            display: inline-block;
            float: left;

            &:first-child {
                width: calc(70% - 5px);
            }
            &:last-child {
                margin-left: 10px;
                width: calc(30% - 5px);
            }
        }
    }

    [data-step] {
        &:not(.active) {
            display: none;
        }
    }

    .text-container {
        font-size: 14px;
        margin-top: 6px;
    }

    .uppercase {
        color: #6c6c6c;
        font-family: $font-family-headline;
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 20px;
        padding-top: 0;
        text-transform: uppercase;
    }
}

/*.article .content .agendasandminutes,*/
.article .content .subscribeagendasandminutes {
    /*.subscribeagendasandminutes {*/
    float: right;
    /*width: 216px;*/
    .button {
        text-decoration: none;
        cursor: pointer;
        color:#fff;
    }

    .input-container {
        &:first-child {
            margin-bottom: 15px;
        }
    }
    /*}*/
}
